import { EditableMultiSelectField, EditableTextField } from '@/components/ui';
import { Switch } from '@/components/ui/switch';
import useGetFormValue from '@/form/useGetFormValue';
import { candidateCareSetting, candidateJobType, candidateShift } from '@/lib/api/v1';
import { NumberString } from '@/lib/base';
import { cn, isValidFullName, stringToDigitsOnly } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { profile as strings } from '@/strings';
import { validate as validateEmail } from 'email-validator';
import { isEmpty } from 'lodash-es';
import { Controller } from 'react-hook-form';

import Delimiter from './Delimiter';
import SalaryInput from './SalaryInput';
import { ProfileFormProps } from './types';

function ProfileForm({
    control,
    errors,
    showAdditionalAttributes,
    originalValues,
    getValues,
    setValue,
}: ProfileFormProps) {
    const getValue = useGetFormValue({ getValues });
    const trackInputEdit = (name: string, onEdit: boolean) => {
        if (onEdit) {
            trackEvent(MixpanelEvent.ProfileStartFieldEdit, { field: name });
        } else {
            trackEvent(MixpanelEvent.ProfileFinishFieldEdit, { field: name });
        }
    };
    return (
        <>
            <div className={cn('mt-[40px] w-full flex flex-col gap-14')}>
                <div className={cn('flex flex-col gap-10')}>
                    <Controller
                        control={control}
                        name='name'
                        rules={{
                            required: strings.errors.requiredField,
                            validate: (value) =>
                                !value || !isValidFullName(value)
                                    ? strings.errors.invalidFullname
                                    : undefined,
                        }}
                        render={() => (
                            <EditableTextField
                                originalValue={originalValues['name']}
                                value={getValue('name')}
                                setValue={(v) => setValue('name', v)}
                                error={errors['name']?.message}
                                label={strings.name.label}
                                onEditStateChange={(bool) =>
                                    trackInputEdit('name', bool)
                                }
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='yearsOfExperience'
                        rules={{
                            required: strings.errors.requiredField,
                            validate: (value: NumberString) =>
                                originalValues['yearsOfExperience'] ===
                                getValue('yearsOfExperience')
                                    ? undefined
                                    : isNaN(Number(value))
                                      ? strings.errors.invalidNumber
                                      : Number(value) > 99
                                        ? strings.errors.yearsExperienceOutOfRange
                                        : undefined,
                        }}
                        render={() => (
                            <EditableTextField
                                originalValue={originalValues['yearsOfExperience']}
                                type='tel'
                                value={getValue('yearsOfExperience')}
                                setValue={(v) =>
                                    setValue('yearsOfExperience', stringToDigitsOnly(v))
                                }
                                error={errors['yearsOfExperience']?.message}
                                label={strings.yearsOfExp.label}
                                onEditStateChange={(bool) =>
                                    trackInputEdit('yearsOfExperience', bool)
                                }
                                suffixElement={
                                    <p className={cn('text-14')}>
                                        {strings.yearsOfExp.inputSuffix}
                                    </p>
                                }
                            />
                        )}
                    />
                    <SalaryInput
                        control={control}
                        state={getValue('hourlySalary')}
                        originalState={originalValues['hourlySalary']}
                        error={errors['hourlySalary']}
                        setValue={setValue}
                        trackInputEdit={(val) => trackInputEdit('hourlySalary', val)}
                    />
                    <Controller
                        control={control}
                        name='shifts'
                        rules={{ required: strings.errors.requiredField }}
                        render={() => (
                            <EditableMultiSelectField
                                originalValue={originalValues['shifts']}
                                value={getValue('shifts')}
                                setValue={(v) => setValue('shifts', v.sort())}
                                transformator={(vs) =>
                                    vs.map((p) => strings.shifts.values[p]).join(', ')
                                }
                                error={errors.shifts?.message}
                                onEditStateChange={(bool) =>
                                    trackInputEdit('shifts', bool)
                                }
                                options={candidateShift.map((p) => ({
                                    label: strings.shifts.values[p],
                                    id: p,
                                    value: p,
                                }))}
                                label={strings.shifts.label}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='jobTypes'
                        rules={{ required: strings.errors.requiredField }}
                        render={() => (
                            <EditableMultiSelectField
                                originalValue={originalValues['jobTypes']}
                                value={getValue('jobTypes')}
                                setValue={(v) => setValue('jobTypes', v.sort())}
                                transformator={(vs) =>
                                    vs.map((p) => strings.jobTypes.values[p]).join(', ')
                                }
                                error={errors.jobTypes?.message}
                                onEditStateChange={(bool) =>
                                    trackInputEdit('jobTypes', bool)
                                }
                                options={candidateJobType.map((p) => ({
                                    label: strings.jobTypes.values[p],
                                    id: p,
                                    value: p,
                                }))}
                                label={strings.jobTypes.label}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='careSettings'
                        rules={{
                            required: isEmpty(originalValues.careSettings)
                                ? undefined
                                : strings.errors.requiredField,
                        }}
                        render={() => (
                            <EditableMultiSelectField
                                originalValue={originalValues.careSettings}
                                value={getValue('careSettings')}
                                setValue={(v) => setValue('careSettings', v.sort())}
                                transformator={(vs) =>
                                    vs
                                        .map((p) => strings.careSettings.values[p])
                                        .join(', ')
                                }
                                error={errors.careSettings?.message}
                                onEditStateChange={(bool) =>
                                    trackInputEdit('careSettings', bool)
                                }
                                options={candidateCareSetting.map((p) => ({
                                    label: strings.careSettings.values[p],
                                    id: p,
                                    value: p,
                                }))}
                                label={strings.careSettings.label}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='email'
                        rules={{
                            validate: (value) =>
                                !value || !validateEmail(value)
                                    ? strings.errors.invalidEmail
                                    : undefined,
                        }}
                        render={() => (
                            <EditableTextField
                                originalValue={originalValues.email}
                                value={getValue('email')}
                                setValue={(v) => setValue('email', v)}
                                error={errors.email?.message}
                                label={strings.email.label}
                                onEditStateChange={(bool) =>
                                    trackInputEdit('email', bool)
                                }
                            />
                        )}
                    />
                </div>
                {showAdditionalAttributes && (
                    <>
                        <Delimiter />
                        <div className={cn('w-full flex flex-col gap-14 pb-4')}>
                            <Controller
                                control={control}
                                name='hasDrivingLicense'
                                render={({ field }) => (
                                    <div className={cn('w-full flex justify-between')}>
                                        <div className={cn('font-semibold text-15')}>
                                            {strings.hasDrivingLicense.label}
                                        </div>
                                        <Switch
                                            onCheckedChange={(v) => {
                                                field.onChange(v);
                                                trackEvent(
                                                    MixpanelEvent.ProfileUpdateSwitchValue,
                                                    {
                                                        field: 'hasDrivingLicense',
                                                        new_value: v,
                                                    },
                                                );
                                            }}
                                            checked={field.value}
                                        />
                                    </div>
                                )}
                            />
                            <Controller
                                control={control}
                                name='hasCar'
                                render={({ field }) => (
                                    <div className={cn('w-full flex justify-between')}>
                                        <div className={cn('font-semibold text-15')}>
                                            {strings.hasCar.label}
                                        </div>
                                        <Switch
                                            onCheckedChange={(v) => {
                                                field.onChange(v);
                                                trackEvent(
                                                    MixpanelEvent.ProfileUpdateSwitchValue,
                                                    {
                                                        field: 'hasCar',
                                                        new_value: v,
                                                    },
                                                );
                                            }}
                                            checked={field.value}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                    </>
                )}
                <Delimiter className={cn('opacity-0')} />
            </div>
        </>
    );
}

export default ProfileForm;
