import Logger from '@/_helpers/logger';
import { firebaseAuth } from '@/firebase';
import { extractErrorMsg } from '@/lib/utils/errors';
import { ConfirmationResult, signInWithPhoneNumber } from 'firebase/auth';
import { useCallback } from 'react';

import getRecaptchaVerifier from './recaptcha/verifier';

export const usePhoneSignIn = () => {
    const signinWithPhone = useCallback(
        async (phoneNumber: string, callback: (res: ConfirmationResult) => void) => {
            try {
                await signInWithPhoneNumber(
                    firebaseAuth,
                    phoneNumber,
                    getRecaptchaVerifier(),
                ).then((confirmationResult: ConfirmationResult) => {
                    callback(confirmationResult);
                });
            } catch (error) {
                Logger.error(`Failure in signinWithPhone ${extractErrorMsg(error)}`);
                throw error;
            }
        },
        [],
    );

    return { signinWithPhone };
};
export default usePhoneSignIn;
