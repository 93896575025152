import { LoadingButton } from '@/components/ui';
import { cn } from '@/lib/utils';
import { registration as strings } from '@/strings';

import { useRegisterFormContext } from './RegisterContext';
import { RegistrationFooterProps } from './types';

function RegistrationFooter({
    disabled = false,
    verifier,
    sideEffefct,
    additionalButtons,
    nextButtonProps,
}: RegistrationFooterProps) {
    const { defaultGoNext, customGoNextAction } = useRegisterFormContext();
    const goNext = () => {
        if (verifier && !verifier()) {
            return;
        }
        sideEffefct && sideEffefct();
        defaultGoNext();
    };
    return (
        <div
            className={
                'relative w-full flex flex-col items-center gap-8 py-8 px-12 border-t border-neutral200'
            }
        >
            <LoadingButton
                className={cn('w-full max-w-[350px] text-14 font-bold')}
                size={'lg'}
                disabled={disabled}
                onClick={customGoNextAction ?? goNext}
                {...nextButtonProps}
            >
                {strings.nextStep}
            </LoadingButton>
            {additionalButtons}
        </div>
    );
}

export default RegistrationFooter;
