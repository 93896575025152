import { PHONE_NUMBER_LENGTH, PhoneNumberInput } from '@/components/ui';
import { PixelEventName, trackFbPixelEventOnce } from '@/facebookPixel';
import useGoTo from '@/hooks/useGoTo';
import apiClient from '@/lib/api';
import { ApiErrorType } from '@/lib/types';
import {
    cn,
    formattedPhoneToInternationalNumber,
    stringToDigitsOnly,
} from '@/lib/utils';
import { extractErrorMsg, extractErrorType } from '@/lib/utils/errors';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import useActionWithRecaptchaObserver from '@/phoneSignIn/recaptcha/useActionWithRecaptchaObserver';
import { registration as strings } from '@/strings';
import { useCallback, useEffect, useState } from 'react';

import { useRegisterFormContext } from '../../RegisterContext';
import RegistrationStepLayout from '../../RegistrationStepLayout';
import StepTitle from '../../StepTitle';
import { getAdditionalAttributes } from '../../utils';

function PhoneNumberForm({
    sendPhoneCode,
    phone,
    setPhone,
}: {
    phone: string;
    setPhone: (phone: string) => void;
    sendPhoneCode: (phone: string) => Promise<void>;
}) {
    const { goToHome } = useGoTo();
    const {
        setCustomGoNextAction,
        jobTypes,
        profession,
        shifts,
        careSettings,
        hasCar,
        hasDrivingLicense,
        yearsExp,
        fullname,
        zipcode,
        email,
        utmData,
        hasRegistered,
        setHasRegistered,
    } = useRegisterFormContext();
    const [error, setError] = useState<{ msg: string; type?: string }>({ msg: '' });
    const updateErrorMsg = (errorMsg: string) => {
        setError({ msg: errorMsg, type: undefined });
    };

    const phoneNumber = stringToDigitsOnly(phone);
    const formattedPhoneNumber = formattedPhoneToInternationalNumber(phone);

    const onSubmit = useCallback(async () => {
        trackEvent(MixpanelEvent.RegistrationSubmitPhone, {
            phone: formattedPhoneNumber,
        });
        trackFbPixelEventOnce(PixelEventName.SubmittedPhoneNumber);
        try {
            setError({ msg: '' });
            if (!hasRegistered) {
                await apiClient
                    .POST('/api/v1/public/register', {
                        body: {
                            jobTypes,
                            profession,
                            shifts,
                            careSettings,
                            yearsOfExperience: yearsExp,
                            phone: formattedPhoneNumber,
                            name: fullname,
                            zipcode,
                            email,
                            utm: utmData,
                            ...getAdditionalAttributes({
                                profession,
                                hasCar,
                                hasDrivingLicense,
                            }),
                        },
                    })
                    .then(() => {
                        setHasRegistered(true);
                    });
            }
            await sendPhoneCode(formattedPhoneNumber);
        } catch (e) {
            setError({ msg: extractErrorMsg(e), type: extractErrorType(e) });
        }
    }, [
        sendPhoneCode,
        jobTypes,
        profession,
        shifts,
        careSettings,
        hasCar,
        hasDrivingLicense,
        yearsExp,
        formattedPhoneNumber,
        fullname,
        zipcode,
        email,
        utmData,
        hasRegistered,
        setHasRegistered,
    ]);

    const { action: submitAndTrackRecaptcha, isLoading } =
        useActionWithRecaptchaObserver(onSubmit);

    useEffect(() => {
        setCustomGoNextAction(submitAndTrackRecaptcha);
        return () => {
            setCustomGoNextAction(null);
        };
    }, [submitAndTrackRecaptcha, setCustomGoNextAction]);

    const canGoNext = phoneNumber.length == PHONE_NUMBER_LENGTH;

    const isAlreadyRegisteredError = error.type === ApiErrorType.PHONE_NUMBER_TAKEN;

    return (
        <RegistrationStepLayout
            canGoNext={canGoNext}
            nextButtonProps={{
                loading: isLoading,
            }}
        >
            <div className={cn('w-full h-full flex flex-col items-center')}>
                <div className={cn('flex flex-col gap-8')}>
                    <StepTitle
                        title={strings.phone.title}
                        subtitle={strings.phone.subtitle}
                    />
                    <div className={cn('w-full max-w-[350px]')}>
                        <PhoneNumberInput
                            value={phone}
                            setValue={setPhone}
                            error={error.msg}
                            errorElemProps={{
                                className: cn({ hidden: isAlreadyRegisteredError }),
                            }}
                            setError={updateErrorMsg}
                        />
                        {isAlreadyRegisteredError && (
                            <p className={cn('mt-8 font-medium text-14')}>
                                {strings.phone.phoneNumberTakenError.mainMessage}{' '}
                                <span
                                    className={cn('font-bold cursor-pointer')}
                                    onClick={() => {
                                        trackEvent(
                                            MixpanelEvent.RegistrationPhoneNumberTakenClickSignInLink,
                                        );
                                        goToHome({ phoneNumber });
                                    }}
                                >
                                    <span className={cn('underline')}>
                                        {strings.phone.phoneNumberTakenError.linkText}
                                    </span>
                                    .
                                </span>
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default PhoneNumberForm;
