import useGoTo from '@/hooks/useGoTo';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { registration as strings } from '@/strings';

import useFinishRegistration from '../useFinishRegistration';

function SkipButton() {
    const { goToHome } = useGoTo();
    const finishRegistration = useFinishRegistration();
    const onClick = () => {
        trackEvent(MixpanelEvent.RegistrationSkipResumeStep);
        goToHome();
        finishRegistration();
    };
    return (
        <div
            className={cn(
                'relative h-8 flex items-center justify-center',
                'text-14 font-bold cursor-pointer',
            )}
            onClick={onClick}
        >
            {strings.resume.skipButton}
        </div>
    );
}

export default SkipButton;
