import { AppPageId } from '@/lib/types';
import { HomePage, ProfilePage, RegisterPage, StatusPage } from '@/pages';

export const pageIdToView = {
    [AppPageId.Home]: <HomePage />,
    [AppPageId.Profile]: <ProfilePage />,
    [AppPageId.Status]: <StatusPage />,
    [AppPageId.Register]: <RegisterPage />,
};

export const anonymousPageIdToView = {
    [AppPageId.Register]: <RegisterPage />,
};
