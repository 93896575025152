import { useToast } from '@/components/ui';
import FileUploadButton from '@/components/ui/fileInputButton';
import { resumeFileExtension } from '@/lib/api/v1';
import { extractErrorMsg } from '@/lib/utils/errors';
import { registration as strings } from '@/strings';

import { uploadResumeFile } from './utils';

function UploadCVButton({
    onFileChange,
    onOpenSelection,
    candidateId,
}: {
    candidateId: string;
    onFileChange: (file: File | null) => void;
    onOpenSelection: () => void;
}) {
    const { toast } = useToast();
    return (
        <FileUploadButton
            label={strings.resume.uploadResumeButton}
            fileExt={resumeFileExtension}
            onOpenSelection={onOpenSelection}
            onFileClear={() => onFileChange(null)}
            onFileSelect={async (file) => {
                await uploadResumeFile(candidateId, file)
                    .then(() => onFileChange(file))
                    .catch((error) => {
                        toast({
                            title: strings.resume.uploadResumeFailureTitle,
                            description: extractErrorMsg(error),
                            variant: 'destructive',
                        });
                        throw error;
                    });
            }}
        />
    );
}

export default UploadCVButton;
