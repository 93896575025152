import { IsLoggedIn } from '@/_selectors';
import { formattedPhoneToInternationalNumber } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import useLogin from '@/phoneSignIn/useLogin';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import CodeVerificationForm from './registration/CodeVerificationForm';
import PhoneNumberForm from './registration/PhoneNumberForm';
import RegisterSuccess from './registration/RegisterSuccess';

enum FormStep {
    Phone = 'phone',
    Code = 'code',
}

function RegisterStep() {
    const [formStep, setFormStep] = useState<FormStep>(FormStep.Phone);
    const isLoggedIn = useSelector(IsLoggedIn);

    const { phoneNumber, setPhoneNumber, sendPhoneCode, submitConfirmationCode } =
        useLogin(true);

    const onSendPhoneCode = useCallback(
        async (phone: string) => {
            await sendPhoneCode(phone).then(() => {
                setFormStep(FormStep.Code);
                trackEvent(MixpanelEvent.RegistrationEnterCodeVerificationStep);
            });
        },
        [sendPhoneCode],
    );
    const resendCode = useCallback(async () => {
        const internationalPhoneNumber =
            formattedPhoneToInternationalNumber(phoneNumber);
        await sendPhoneCode(internationalPhoneNumber);
    }, [sendPhoneCode, phoneNumber]);
    const goBackToPhoneInput = useCallback(() => setFormStep(FormStep.Phone), []);

    return (
        <>
            {isLoggedIn && <RegisterSuccess />}
            {!isLoggedIn && (
                <>
                    {formStep === FormStep.Phone && (
                        <PhoneNumberForm
                            sendPhoneCode={onSendPhoneCode}
                            phone={phoneNumber}
                            setPhone={setPhoneNumber}
                        />
                    )}
                    {formStep === FormStep.Code && (
                        <CodeVerificationForm
                            submit={submitConfirmationCode}
                            resendCode={resendCode}
                            goBack={goBackToPhoneInput}
                            phone={phoneNumber}
                        />
                    )}
                </>
            )}
        </>
    );
}

export default RegisterStep;
