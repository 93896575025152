import Logger from '@/_helpers/logger';
import { TextInput } from '@/components/ui';
import apiClient from '@/lib/api';
import { cn } from '@/lib/utils';
import { extractErrorMsg } from '@/lib/utils/errors';
import { registration as strings } from '@/strings';

import { useRegisterFormContext } from '../RegisterContext';
import RegistrationStepLayout from '../RegistrationStepLayout';
import StepTitle from '../StepTitle';

function SalaryStep() {
    const { salary, setSalary } = useRegisterFormContext();
    const updateVal = (val: string) => {
        if (val === '') {
            setSalary(null);
        } else if (/^\d*$/.test(val)) {
            setSalary(Number(val));
        }
    };

    const onSubmit = () => {
        const salaryPayload =
            salary == null
                ? {
                      hourlySalary: null,
                      hourlySalaryFlexible: true,
                  }
                : {
                      hourlySalary: salary,
                      hourlySalaryFlexible: false,
                  };
        apiClient
            .PATCH('/api/v1/candidates/me', {
                body: salaryPayload,
            })
            .catch((error) => {
                Logger.error(extractErrorMsg(error));
            });
    };
    return (
        <RegistrationStepLayout canGoNext sideEffefct={onSubmit}>
            <div className={cn('w-full h-full flex flex-col items-center')}>
                <div className={cn('flex flex-col gap-8')}>
                    <div className={cn('w-full max-w-[400px]')}>
                        <StepTitle
                            title={strings.salary.title}
                            subtitle={strings.salary.subtitle}
                        />
                    </div>
                    <div className={cn('w-full max-w-[400px]')}>
                        <TextInput
                            type='tel'
                            maxLength={3}
                            prefixElement={<span className={cn('mr-1')}>$</span>}
                            value={String(salary ?? '')}
                            setValue={updateVal}
                        />
                    </div>
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default SalaryStep;
