import { useAppDispatch } from '@/_helpers/store';
import { resetCandidateAppState } from '@/_reducers/appState';
import { resetCandidate } from '@/_reducers/candidate';
import { resetOrganizations } from '@/_reducers/organizations';
import { resetProcesses } from '@/_reducers/processes';
import { resetScreeningData } from '@/_reducers/screening';
import { resetFetcherMeneger } from '@/fetchers/useFetcherBase';

function useResetRecruiterData() {
    const dispatch = useAppDispatch();
    const reset = () => {
        dispatch(resetProcesses());
        dispatch(resetCandidate());
        dispatch(resetCandidateAppState());
        dispatch(resetScreeningData());
        dispatch(resetOrganizations());
        resetFetcherMeneger();
    };
    return {
        reset,
    };
}

export default useResetRecruiterData;
