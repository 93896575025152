import Loader from '@/components/lotties/Loader';
import { cn } from '@/lib/utils';
import * as React from 'react';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    inputClassName?: string;
    rootProps?: React.HTMLAttributes<HTMLDivElement>;
    prefixElement?: React.ReactNode;
    suffixElement?: React.ReactNode;
    containerRef?: React.RefObject<HTMLDivElement>;
}

const InputContainerElement = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLInputElement> & {
        disabled?: boolean;
    }
>(({ className, disabled, children, ...props }, ref) => {
    return (
        <div
            ref={ref}
            {...props}
            className={cn(
                'relative flex items-center h-[54px] w-full',
                'px-6 text-16 font-medium',
                'rounded-2xl border  border-primary bg-white',
                { 'opacity-50': disabled },
                className,
            )}
        >
            {children}
        </div>
    );
});
InputContainerElement.displayName = 'InputContainerElement';

const BaseTextInput = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            containerRef,
            className,
            type,
            disabled,
            inputClassName,
            prefixElement,
            suffixElement,
            rootProps,
            ...props
        },
        ref,
    ) => {
        return (
            <InputContainerElement
                ref={containerRef}
                disabled={disabled}
                className={className}
                {...rootProps}
            >
                {prefixElement}
                <input
                    type={type}
                    className={cn(
                        'flex w-full p-0',
                        'file:border-0 file:bg-transparent file:text-sm file:font-medium',
                        'placeholder:font-medium placeholder:text-neutral300 focus-visible:outline-none',
                        'disabled:cursor-not-allowed',
                        inputClassName,
                    )}
                    ref={ref}
                    disabled={disabled}
                    {...props}
                />
                {suffixElement}
            </InputContainerElement>
        );
    },
);
BaseTextInput.displayName = 'BaseTextInput';
export { BaseTextInput };

export enum TextInputClearErrorsPlan {
    ChangeOnly = 'ChangeOnly',
    All = 'All',
}

export interface TextInputProps extends InputProps {
    value: string;
    setValue: (val: string) => void;
    error?: string;
    clearError?: () => void;
    label?: string;
    loading?: boolean;
    clearErrorsPlan?: TextInputClearErrorsPlan;
    errorElemProps?: React.HTMLAttributes<HTMLParagraphElement>;
}

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
    (
        {
            error,
            clearError,
            value,
            setValue,
            label,
            loading = false,
            clearErrorsPlan = TextInputClearErrorsPlan.ChangeOnly,
            errorElemProps = {},
            ...props
        },
        ref,
    ) => {
        const indicateError = !!error;

        const resetError = () => {
            if (indicateError && clearError) {
                clearError();
            }
        };
        const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
            setValue(ev.target.value);
            resetError();
        };
        return (
            <div className={cn('w-full relative')}>
                {label && <h4 className={cn('font-semibold text-13 mb-3')}>{label}</h4>}
                <BaseTextInput
                    ref={ref}
                    value={value}
                    onChange={handleChange}
                    onClick={
                        clearErrorsPlan === TextInputClearErrorsPlan.All
                            ? resetError
                            : undefined
                    }
                    onFocus={
                        clearErrorsPlan === TextInputClearErrorsPlan.All
                            ? resetError
                            : undefined
                    }
                    {...props}
                    className={cn(
                        {
                            'border-red200 text-red200': indicateError,
                        },
                        props.className,
                    )}
                    suffixElement={
                        <>
                            {props.suffixElement}
                            {loading && (
                                <div
                                    className={cn(
                                        'absolute top-0 right-6 h-full flex items-center',
                                    )}
                                >
                                    <Loader sizing={'md'} variant={'primary'} />
                                </div>
                            )}
                        </>
                    }
                />
                {indicateError && !loading && (
                    <p
                        {...errorElemProps}
                        className={cn(
                            'mt-8 font-medium text-red200 text-14',
                            errorElemProps?.className,
                        )}
                    >
                        {error}
                    </p>
                )}
            </div>
        );
    },
);
TextInput.displayName = 'TextInput';

export { TextInput };
