import { EventScheduledEvent } from 'react-calendly';

export enum HomePageStatus {
    WaitingData = 'waitingData',
    SearchingFacilities = 'searchingFacilities',
    NoProcesses = 'noProcesses',
    NoMoreProcesses = 'noMoreProcesses',
    Active = 'active',
}

export enum ScreeningFlowState {
    WaitingData = 'waitingData',
    Pending = 'pending',
    Scheduled = 'scheduled',
    Done = 'done',
    hasActiveProcesses = 'hasProcesses',
}

export enum CalendlyWidgetType {
    Schedule = 'schedule',
    Reschedule = 'reschedule',
}

export interface CalendlyWidgetProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    link: string;
    type?: CalendlyWidgetType;
    onEventScheduled?: (event: EventScheduledEvent) => void;
}
