import { OnboardingFormStep, OnboardingQuestion } from '@/lib/types';
import { isValidFullName } from '@/lib/utils';
import { validate as validateEmail } from 'email-validator';
import { isEmpty } from 'lodash-es';

import { FormDataKey } from './types';

export const formStepsOrderd = [
    OnboardingFormStep.LongTermInterest,
    OnboardingFormStep.JobTypes,
    OnboardingFormStep.Profession,
    OnboardingFormStep.Shifts,
    OnboardingFormStep.CareSettings,
    OnboardingFormStep.AdditionalAttrs,
    OnboardingFormStep.YearsExp,
    OnboardingFormStep.ZipCode,
    OnboardingFormStep.Name,
    OnboardingFormStep.Email,
    OnboardingFormStep.Register,
    OnboardingFormStep.Salary,
    OnboardingFormStep.Resume,
];

export const formStepToDataVerifier: Record<
    OnboardingFormStep,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { key: FormDataKey; emptyCheck: (...params: any) => boolean }[]
> = {
    [OnboardingFormStep.LongTermInterest]: [
        {
            key: OnboardingQuestion.LongTermInterest,
            emptyCheck: (val: boolean | null) => val === null,
        },
    ],
    [OnboardingFormStep.CareSettings]: [
        { key: OnboardingQuestion.CareSettings, emptyCheck: isEmpty },
    ],
    [OnboardingFormStep.Profession]: [
        { key: OnboardingQuestion.Profession, emptyCheck: isEmpty },
    ],
    [OnboardingFormStep.JobTypes]: [
        { key: OnboardingQuestion.JobTypes, emptyCheck: isEmpty },
    ],
    [OnboardingFormStep.Shifts]: [
        { key: OnboardingQuestion.Shifts, emptyCheck: isEmpty },
    ],
    [OnboardingFormStep.AdditionalAttrs]: [],
    [OnboardingFormStep.YearsExp]: [
        {
            key: OnboardingQuestion.Shifts,
            emptyCheck: (val: number | null) => val === null,
        },
    ],
    [OnboardingFormStep.ZipCode]: [
        { key: OnboardingQuestion.ZipCode, emptyCheck: isEmpty },
    ],
    [OnboardingFormStep.Name]: [
        {
            key: OnboardingQuestion.Name,
            emptyCheck: (fullname: string) => !isValidFullName(fullname),
        },
    ],
    [OnboardingFormStep.Email]: [
        {
            key: OnboardingQuestion.Email,
            emptyCheck: (email: string) => !validateEmail(email),
        },
    ],
    [OnboardingFormStep.Register]: [
        {
            key: OnboardingQuestion.Phone,
            emptyCheck: () => true,
        },
    ],
    [OnboardingFormStep.Salary]: [],
    [OnboardingFormStep.Resume]: [
        {
            key: OnboardingQuestion.Salary,
            emptyCheck: () => true,
        },
    ],
};
