import { DIALOG_ANIMATION_LENGTH } from '@/_reducers/dialogs';
import { useEffect } from 'react';

import { useDialogContext } from './context';

export function useScrollTracker() {
    const { setScrolledDownPosition, scrollRef, open, isExpandedToTop } =
        useDialogContext();

    const scrollElement = scrollRef.current;
    useEffect(() => {
        if (scrollElement && isExpandedToTop) {
            scrollElement.onscroll = () => {
                setScrolledDownPosition(scrollElement.scrollTop);
            };
        }
    }, [scrollElement, isExpandedToTop, setScrolledDownPosition]);

    useEffect(() => {
        if (!open) {
            setTimeout(() => {
                setScrolledDownPosition(0);
            }, DIALOG_ANIMATION_LENGTH);
        }
    }, [open, setScrolledDownPosition]);
}
