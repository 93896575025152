import { DialogContentWithFooter, LoadingButton, useToast } from '@/components/ui';
import { Textarea } from '@/components/ui/textarea';
import apiClient from '@/lib/api';
import { InAppSupportType } from '@/lib/api/v1';
import { cn } from '@/lib/utils';
import { extractErrorMsg } from '@/lib/utils/errors';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { dialogs as dialogsStrings } from '@/strings';
import { useState } from 'react';

const strings = dialogsStrings.talkToUs;

export function TalkToUsContent({
    submitCallback,
    sectionName,
    supportType,
}: {
    submitCallback: () => void;
    sectionName: string;
    supportType?: InAppSupportType;
}) {
    const { toast } = useToast();
    const [text, setText] = useState('');
    const submit = async () => {
        trackEvent(MixpanelEvent.TalkToUsSubmitForm);
        await apiClient
            .POST('/api/v1/messages/in-app', {
                body: {
                    body: `${text}\n[Section: ${sectionName}]`,
                    type: supportType,
                },
            })
            .then(submitCallback)
            .catch((error) => {
                toast({
                    title: strings.submit.failureTitle,
                    description: extractErrorMsg(error),
                    variant: 'destructive',
                });
            });
    };
    return (
        <DialogContentWithFooter
            footer={
                <div className={cn('flex justify-center')}>
                    <LoadingButton
                        className={cn('w-full max-w-[400px]')}
                        onClick={submit}
                        disabled={!text.trim()}
                    >
                        {strings.submitButton}
                    </LoadingButton>
                </div>
            }
        >
            <Textarea
                rows={8}
                placeholder={strings.textboxPlaceholder}
                value={text}
                onChange={(e) => setText(e.target.value)}
                className={cn('resize-none max-h-full')}
            />
        </DialogContentWithFooter>
    );
}

export default TalkToUsContent;
