import Loader from '@/components/lotties/Loader';
import { cn } from '@/lib/utils';
import { registration as strings } from '@/strings';

import useActionWithRecaptchaObserver from './recaptcha/useActionWithRecaptchaObserver';

function ResendCodeButton({
    resendCode: resendCodeAction,
    ...props
}: React.HTMLAttributes<HTMLDivElement> & {
    resendCode: () => Promise<void>;
}) {
    const { action: resendCode, isLoading } =
        useActionWithRecaptchaObserver(resendCodeAction);
    return (
        <>
            <div
                {...props}
                className={cn(
                    'relative h-8 flex items-center justify-center',
                    { 'pointer-events-none': isLoading },
                    props.className,
                )}
            >
                {isLoading && (
                    <div
                        className={cn(
                            'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
                        )}
                    >
                        <Loader sizing={'md'} variant={'primary'} />
                    </div>
                )}
                <span
                    className={cn('text-14 font-bold cursor-pointer', {
                        'opacity-0': isLoading,
                    })}
                    onClick={resendCode}
                >
                    {strings.verificationCode.resendCodeButton}
                </span>
            </div>
        </>
    );
}

export default ResendCodeButton;
