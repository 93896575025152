import { getAllEnumValues } from '@/lib/base';
import { OnboardingFormStep, OnboardingQuestion, Profession } from '@/lib/types';

import { formStepsOrderd } from './steps';
import { AdditionalAttribiutes, FormDataKey, RegisterFormState } from './types';

export function isOnboardingQuestion(key: FormDataKey): key is OnboardingQuestion {
    return getAllEnumValues(OnboardingQuestion).includes(key as OnboardingQuestion);
}

export function getOrderedFormSteps(profession: string) {
    let res = [...formStepsOrderd];
    if (profession !== Profession.HHA) {
        res = res.filter((s) => s !== OnboardingFormStep.AdditionalAttrs);
    }
    return res;
}

export function getAdditionalAttributes({
    profession,
    hasCar,
    hasDrivingLicense,
}: Pick<
    RegisterFormState,
    'profession' | 'hasCar' | 'hasDrivingLicense'
>): AdditionalAttribiutes {
    if (profession !== Profession.HHA) {
        return {};
    }
    return { hasCar, hasDrivingLicense };
}
