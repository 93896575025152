import { fetchScreeningLink } from '@/_reducers/screening';
import { CurrentScreeningLink, ScreeningLinkFetcherData } from '@/_selectors';
import { LoadingButton } from '@/components/ui';
import useFetcherBase from '@/fetchers/useFetcherBase';
import { cn, stringToDigitsOnly } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { homepage as strings } from '@/strings';
import { useState } from 'react';

import { CalendlyWidgetProps, CalendlyWidgetType } from '../types';
import CalendlyWidget from './CalendlyWidget';

const TARGET_PHONE_NUMBER = '332-999-9337';
const START_WEEK_DAY = 0;
const END_WEEK_DAY = 4;
const START_WORKING_HOUR = 10;
const END_WORKING_HOUR = 18;
const TIMEZONE = 'ET';

function ScheduleIntroCallView({
    registerEvent,
    hasOngoingProcesses,
}: {
    registerEvent: CalendlyWidgetProps['onEventScheduled'];
    hasOngoingProcesses: boolean;
}) {
    const { data: link, isWaiting } = useFetcherBase({
        fetchFunc: fetchScreeningLink,
        dataSelector: CurrentScreeningLink,
        fetcherSelector: ScreeningLinkFetcherData,
    });
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={cn('flex items-center justify-center py-12')}>
            <div className={cn('relative w-full h-full pt-4 pb-2 mb-auto px-8')}>
                <div className={cn('text-center text-24 leading-[1.5] font-bold')}>
                    {strings.scheduleIntroCall.title}
                </div>
                <div className={cn('text-center text-15 font-medium mt-3')}>
                    <span>
                        {hasOngoingProcesses
                            ? strings.scheduleIntroCall.subtitle.part1
                                  .inProgressCandidate
                            : strings.scheduleIntroCall.subtitle.part1.newCandidate}
                    </span>{' '}
                    <span>
                        <u>
                            <a href={`tel:+1${stringToDigitsOnly(TARGET_PHONE_NUMBER)}`}>
                                {strings.scheduleIntroCall.subtitle.part2.func(
                                    TARGET_PHONE_NUMBER,
                                )}
                            </a>
                        </u>
                    </span>{' '}
                    <span>
                        {strings.scheduleIntroCall.subtitle.part3.func(
                            START_WEEK_DAY,
                            END_WEEK_DAY,
                            START_WORKING_HOUR,
                            END_WORKING_HOUR,
                            TIMEZONE,
                        )}
                    </span>
                </div>
                <div className={cn('flex w-full justify-center pt-8')}>
                    <LoadingButton
                        variant={'default'}
                        size={'lg'}
                        className={cn('px-14 text-13')}
                        loading={isWaiting && isOpen}
                        onClick={() => {
                            trackEvent(MixpanelEvent.IntroCallClickSchedule);
                            setIsOpen(true);
                        }}
                    >
                        {strings.scheduleIntroCall.button}
                    </LoadingButton>
                </div>
                {link && isOpen && (
                    <CalendlyWidget
                        open={isOpen}
                        setOpen={setIsOpen}
                        link={link}
                        type={CalendlyWidgetType.Schedule}
                        onEventScheduled={registerEvent}
                    />
                )}
            </div>
        </div>
    );
}

export default ScheduleIntroCallView;
