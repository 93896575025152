import { DialogContentWithFooter } from '@/components/ui';
import { Textarea } from '@/components/ui/textarea';
import { cn } from '@/lib/utils';
import { homepage as hompagteStrings } from '@/strings';
import { useState } from 'react';

import CancelScreeningDialogFooter from './footer';
import { ContentProps } from './types';

const strings = hompagteStrings.screeningEvent.cancelDialg;

export function CancelScreeningDialogContent(props: ContentProps) {
    const [reason, setReason] = useState('');

    return (
        <DialogContentWithFooter
            footer={<CancelScreeningDialogFooter {...props} reason={reason} />}
        >
            <div className={cn('relative w-full')}>
                <div className={cn('p-0 pb-4 text-left h-fit')}>
                    <p className={cn('text-18 font-semibold')}>{strings.title}</p>
                    <p className={cn('text-14 mt-4')}>{strings.subtitle}</p>
                    <div className={cn('realtive h-[150px]')}>
                        <Textarea
                            className={cn('mt-4 h-full resize-none')}
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </DialogContentWithFooter>
    );
}

export default CancelScreeningDialogContent;
