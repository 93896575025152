const strings = {
    loginTitle: 'Sign in to Carefam',
    loginSubtitle: 'Enter your phone number',
    submitButton: 'Next',
    linkToSignUp: 'Sign up',
    errors: {
        shouldRegister:
            'This phone number does not appear in our system. Sign up for a new account.',
    },
    codeVerification: {
        title: 'Enter the 6-digit code',
        subtitle: {
            func: (formattedPhone: string) => `We sent it to ${formattedPhone}`,
            example: ['+1 123 456 7890'],
        },
        resendCodeButton: 'Resend code',
        error: 'Could not verify code',
        invalidCodeError: 'Please enter the correct code',
    },
};

export default strings;
