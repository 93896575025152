import Logger from '@/_helpers/logger';
import { IsLoggedIn } from '@/_selectors';
import useGoTo from '@/hooks/useGoTo';
import useQueryParamsAndClear from '@/hooks/useQueryParamsAndClear';
import { CandidateCareSetting, CandidateJobType, CandidateShift } from '@/lib/api/v1';
import { SimpleFuncOrNull } from '@/lib/base';
import {
    OnboardingFormStep,
    OnboardingQuestion,
    OnboardingQuestionWithAnswer,
} from '@/lib/types';
import { localStorageGraceful } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { debounce, isEmpty } from 'lodash-es';
import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import { formStepToDataVerifier } from './steps';
import {
    FormAdditionalDataKey,
    FormDataKey,
    FormDataValue,
    RegisterFormState,
} from './types';
import { getOrderedFormSteps, isOnboardingQuestion } from './utils';
import { utmParams } from './utmParams';

const RegisterFormContext = createContext({} as RegisterFormState);

function toggleSelect<T>(val: T, state: T[], setState: (vals: T[]) => void) {
    if (!state.includes(val)) {
        setState([...state, val]);
    } else {
        setState(state.filter((v) => v !== val));
    }
}

const MAIN_KEY = 'carefam_register_form';
const getStoredState = () => {
    const storedState = localStorageGraceful?.getItem(MAIN_KEY);
    if (!storedState) {
        return {};
    }
    try {
        const parsedState = JSON.parse(storedState);
        if (typeof parsedState !== 'object') {
            return {};
        }
        return parsedState;
    } catch (e) {
        Logger.error(`Error parsing stored state ${e}`);
        return {};
    }
};

const debouncedTrackEvent = debounce(
    (...args: Parameters<typeof trackEvent>) => trackEvent(...args),
    500,
);

function useStoredState<T extends FormDataKey>(
    key: T,
    defaultVal: FormDataValue<T>,
): [FormDataValue<T>, (val: FormDataValue<T>) => void] {
    const isLoggedIn = useSelector(IsLoggedIn);
    const [val, setVal] = useState<FormDataValue<T>>(() => {
        const storedState = getStoredState();
        if (key === FormAdditionalDataKey.StepIndex) {
            return getFirstStepIndex(isLoggedIn);
        }
        return storedState[key] !== undefined ? storedState[key] : defaultVal;
    });
    useEffect(() => {
        const storedState = getStoredState();
        if (localStorageGraceful && isEmpty(storedState)) {
            Logger.warn(
                `reseting old stored state value: ${localStorageGraceful.getItem(MAIN_KEY)}`,
            );
            localStorageGraceful.setItem(MAIN_KEY, '{}');
        }
    }, []);

    const updateVal = useCallback(
        (newVal: FormDataValue<T>) => {
            if (localStorageGraceful) {
                const storedState = getStoredState();
                const updatedState = { ...storedState, [key]: newVal };
                localStorageGraceful.setItem(MAIN_KEY, JSON.stringify(updatedState));
            }
            setVal(newVal);
            if (isOnboardingQuestion(key)) {
                debouncedTrackEvent(MixpanelEvent.RegistrationUpdateAnswer, {
                    question: key,
                    answer: newVal,
                } as OnboardingQuestionWithAnswer);
            }
        },
        [key],
    );

    return [val, updateVal];
}

function getFirstStepIndex(isLoggedIn = false) {
    const storedData = getStoredState();
    const steps = getOrderedFormSteps(storedData[OnboardingQuestion.Profession]);
    const lastStepIndex = steps.length - 1;
    const registerPageIndex = steps.indexOf(OnboardingFormStep.Register);
    const storedLastVisitStepIndex = storedData[FormAdditionalDataKey.StepIndex];
    const lastVisitStepIndex = isLoggedIn
        ? storedLastVisitStepIndex
            ? storedLastVisitStepIndex > registerPageIndex
                ? storedLastVisitStepIndex
                : registerPageIndex + 1
            : lastStepIndex
        : storedLastVisitStepIndex ?? 0;
    for (const i of steps.keys()) {
        if (isLoggedIn && i <= registerPageIndex) {
            continue;
        }
        const step = steps[i];
        const dataChecks = formStepToDataVerifier[step];
        if (lastVisitStepIndex <= i) {
            return lastVisitStepIndex;
        }
        if (!dataChecks || dataChecks.length === 0) {
            continue;
        }
        for (const check of dataChecks) {
            if (!storedData[check.key] || check.emptyCheck(storedData[check.key])) {
                return i;
            }
        }
    }
    return steps.length - 1;
}

function RegisterFormContextProvider({ children }: { children: ReactNode }) {
    const { goToHome } = useGoTo();
    const [stepIndex, setStepIndex] = useStoredState(FormAdditionalDataKey.StepIndex, 0);
    const [longTermInterest, setLongTermInterest] = useStoredState(
        OnboardingQuestion.LongTermInterest,
        null,
    );
    const [prof, setProf] = useStoredState(OnboardingQuestion.Profession, '');
    const [jobTypes, setJobTypes] = useStoredState(OnboardingQuestion.JobTypes, []);
    const [shifts, setShifts] = useStoredState(OnboardingQuestion.Shifts, []);
    const [careSettings, setCareSettings] = useStoredState(
        OnboardingQuestion.CareSettings,
        [],
    );
    const [hasCar, setHasCar] = useStoredState(OnboardingQuestion.HasCar, null);
    const [hasDrivingLicense, setHasDrivingLicense] = useStoredState(
        OnboardingQuestion.HasDrivingLicense,
        null,
    );
    const [yearsExp, setYearsExp] = useStoredState(OnboardingQuestion.YearsExp, null);
    const [zipcode, setZipcode] = useStoredState(OnboardingQuestion.ZipCode, '');
    const [zipcodeLocation, setZipcodeLocation] = useState('');
    const [fullname, setFullname] = useStoredState(OnboardingQuestion.Name, '');
    const [email, setEmail] = useStoredState(OnboardingQuestion.Email, '');
    const [salary, setSalary] = useStoredState(OnboardingQuestion.Salary, null);
    const [recentJobCompany, setRecentJobCompany] = useStoredState(
        OnboardingQuestion.RecentJobCompany,
        '',
    );
    const [recentJobTitle, setRecentJobTitle] = useStoredState(
        OnboardingQuestion.RecentJobTitle,
        '',
    );
    const [recentJobStart, setRecentJobStart] = useStoredState(
        OnboardingQuestion.RecentJobStart,
        '',
    );
    const [recentJobEnd, setRecentJobEnd] = useStoredState(
        OnboardingQuestion.RecentJobEnd,
        '',
    );
    const [recentJobIsCurrent, setRecentJobIsCurrent] = useStoredState(
        OnboardingQuestion.RecentJobIsCurrent,
        false,
    );
    const [utmData, setUtmData] = useStoredState(FormAdditionalDataKey.UtmData, {});

    const [hasRegistered, setHasRegistered] = useState(false);
    const [preventGoBack, setPreventGoBack] = useState(false);
    const [customGoNextAction, setCustomGoNextAction] = useState<SimpleFuncOrNull>(null);
    const [customGoBackAction, setCustomGoBackAction] = useState<SimpleFuncOrNull>(null);
    const getParamsAndClear = useQueryParamsAndClear(utmParams);

    const toggleJobType = (jt: CandidateJobType) =>
        toggleSelect(jt, jobTypes, setJobTypes);
    const toggleShift = (s: CandidateShift) => toggleSelect(s, shifts, setShifts);
    const toggleCareSetting = (cs: CandidateCareSetting) =>
        toggleSelect(cs, careSettings, setCareSettings);

    const steps = getOrderedFormSteps(prof);
    const currentStep = steps[stepIndex];
    const totalSteps = steps.length;

    useEffect(() => {
        const utmParams = getParamsAndClear();
        if (!isEmpty(utmParams)) {
            setUtmData(utmParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        trackEvent(MixpanelEvent.RegistrationStartStep, {
            current_step: currentStep,
            current_step_index: stepIndex,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepIndex]);

    const currentStepData = useMemo(
        () => ({
            current_step: currentStep,
            current_step_index: stepIndex,
        }),
        [currentStep, stepIndex],
    );

    const defaultGoNext = useCallback(() => {
        trackEvent(MixpanelEvent.RegistrationClickNextStep, currentStepData);
        trackEvent(MixpanelEvent.RegistrationFinishStep, currentStepData);
        const nextStep = stepIndex + 1;
        if (nextStep < totalSteps) {
            setStepIndex(nextStep);
        } else {
            goToHome();
        }
    }, [stepIndex, currentStepData, goToHome, setStepIndex, totalSteps]);

    const defaultGoBack = useCallback(() => {
        trackEvent(MixpanelEvent.RegistrationClickPrevStep, currentStepData);
        if (stepIndex > 0) {
            setStepIndex(stepIndex - 1);
        } else {
            goToHome();
        }
    }, [stepIndex, currentStepData, goToHome, setStepIndex]);

    return (
        <RegisterFormContext.Provider
            value={{
                totalSteps,
                currentStep,
                currentStepIndex: stepIndex,
                stepIndex,
                setStepIndex,
                longTermInterest,
                setLongTermInterest,
                profession: prof,
                setProfession: setProf,
                shifts,
                toggleShift,
                careSettings,
                toggleCareSetting,
                hasDrivingLicense,
                setHasDrivingLicense,
                hasCar,
                setHasCar,
                jobTypes,
                toggleJobType,
                utmData,
                zipcode,
                setZipcode,
                zipcodeLocation,
                setZipcodeLocation,
                fullname,
                setFullname,
                email,
                setEmail,
                yearsExp,
                setYearsExp,
                salary,
                setSalary,
                recentJobCompany,
                setRecentJobCompany,
                recentJobTitle,
                setRecentJobTitle,
                recentJobStart,
                setRecentJobStart,
                recentJobEnd,
                setRecentJobEnd,
                recentJobIsCurrent,
                setRecentJobIsCurrent,
                hasRegistered,
                setHasRegistered,
                preventGoBack,
                setPreventGoBack,
                defaultGoNext,
                defaultGoBack,
                customGoNextAction,
                setCustomGoNextAction: (fn: SimpleFuncOrNull) =>
                    setCustomGoNextAction(() => fn),
                customGoBackAction,
                setCustomGoBackAction: (fn: SimpleFuncOrNull) =>
                    setCustomGoBackAction(() => fn),
            }}
        >
            {children}
        </RegisterFormContext.Provider>
    );
}

export const useRegisterFormContext = () => useContext(RegisterFormContext);

export default RegisterFormContextProvider;
