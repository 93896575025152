import apiClient from '@/lib/api';
import { ResumeFileExtension } from '@/lib/api/v1';
import { getFileExtension } from '@/lib/utils';

export async function uploadResumeFile(candidateId: string, file: File) {
    const fileExt = getFileExtension(file) as ResumeFileExtension;
    const uploadData = await apiClient.GET(
        '/api/v1/candidates/{candidate_id}/resume/upload-url',
        {
            params: {
                path: { candidate_id: candidateId },
                query: { file_ext: fileExt },
            },
        },
    );
    const { url, resumeUuid, contentType } = uploadData.data!;
    const headers = new Headers({ 'Content-Type': contentType });
    await fetch(url, { method: 'PUT', headers, body: file });
    await apiClient.POST('/api/v1/candidates/{candidate_id}/resume/uploaded', {
        params: {
            path: { candidate_id: candidateId },
        },
        body: { resumeUuid, fileExt },
    });
}
