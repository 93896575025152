import Logger from '@/_helpers/logger';
import { useAppDispatch } from '@/_helpers/store';
import { registerAuthUser } from '@/_reducers/auth';
import { PixelEventName, trackFbPixelEventOnce } from '@/facebookPixel';
import { signOutFirebase } from '@/firebase';
import { UserType } from '@/lib/api/v1';
import usePhoneSignIn from '@/phoneSignIn/usePhoneSignIn';
import { ConfirmationResult } from 'firebase/auth';
import { useCallback, useState } from 'react';

function useLogin(isRegistration = false) {
    const dispatch = useAppDispatch();
    const { signinWithPhone } = usePhoneSignIn();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult>();
    const sendPhoneCode = useCallback(
        async (phone: string) => {
            await signinWithPhone(phone, (cr: ConfirmationResult) => {
                setConfirmationResult(cr);
            });
        },
        [signinWithPhone],
    );

    const hasSentCode = !!confirmationResult;

    const submitConfirmationCode = useCallback(
        async (code: string) => {
            if (!confirmationResult) {
                throw new Error('ConfirmationResult is not set');
            }
            const user = await confirmationResult.confirm(code);
            if (!isRegistration) {
                return;
            }
            await user.user.getIdTokenResult().then(async (idTokenResult) => {
                const claims = idTokenResult.claims;
                if (claims.role === UserType.Recruiter) {
                    Logger.error(
                        `a user of type ${UserType.Recruiter} tried to login to candidate-app (id: ${claims.sub})`,
                    );
                    signOutFirebase();
                    return;
                }
                trackFbPixelEventOnce(PixelEventName.RegisteredSuccessfully);
                dispatch(() => registerAuthUser(user.user));
            });
        },
        [confirmationResult, dispatch, isRegistration],
    );

    return {
        phoneNumber,
        setPhoneNumber,
        sendPhoneCode,
        submitConfirmationCode,
        hasSentCode,
    };
}

export default useLogin;
