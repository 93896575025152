import Logger from '@/_helpers/logger';
import { IsDevModeOn } from '@/_selectors';
import {
    LoadingButton,
    PHONE_NUMBER_LENGTH,
    PhoneNumberInput,
    TextInput,
} from '@/components/ui';
import { CANNOT_REGISTER_ERROR_CODE } from '@/firebase';
import {
    cn,
    formattedPhoneToInternationalNumber,
    stringToDigitsOnly,
} from '@/lib/utils';
import { extractErrorType } from '@/lib/utils/errors';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import useActionWithRecaptchaObserver from '@/phoneSignIn/recaptcha/useActionWithRecaptchaObserver';
import strings from '@/strings/login';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { SignInSectionProps } from './types';

function SignInSection({ onSubmit, phone, setPhone }: SignInSectionProps) {
    const isDevMode = useSelector(IsDevModeOn);
    const [error, setError] = useState('');

    const formattedPhoneNumber = formattedPhoneToInternationalNumber(phone);
    const phoneNumberForSubmission = isDevMode ? phone : formattedPhoneNumber;
    const submit = useCallback(async () => {
        trackEvent(MixpanelEvent.LoginSubmitPhoneNumber, {
            phone: phoneNumberForSubmission,
        });
        setError('');
        await onSubmit(phoneNumberForSubmission).catch((e) => {
            if (extractErrorType(e) === CANNOT_REGISTER_ERROR_CODE) {
                setError(strings.errors.shouldRegister);
                return;
            }
            Logger.warn(`Got unhandled error on SignInSection: ${e.message || e}`);
            setError(e.message || e);
        });
    }, [onSubmit, phoneNumberForSubmission]);

    const { action: submitAndTrackRecaptcha, isLoading } =
        useActionWithRecaptchaObserver(submit);

    return (
        <div className={cn('w-full flex justify-center')}>
            <div className={cn('max-w-[360px] w-full')}>
                <div className={cn('')}>
                    <p className={cn('font-bold text-26')}>{strings.loginTitle}</p>
                </div>
                <div className={cn('mt-1')}>
                    <p className={cn('font-medium text-15')}>{strings.loginSubtitle}</p>
                </div>
                <div className={cn('mt-6')}>
                    {isDevMode ? (
                        <TextInput
                            value={phone}
                            setValue={setPhone}
                            error={error}
                            clearError={() => setError('')}
                            placeholder='+972520123456'
                            errorElemProps={{ className: cn('mt-2') }}
                        />
                    ) : (
                        <PhoneNumberInput
                            value={phone}
                            setValue={setPhone}
                            error={error}
                            setError={setError}
                            errorElemProps={{ className: cn('mt-2') }}
                        />
                    )}
                    <LoadingButton
                        loading={isLoading}
                        onClick={submitAndTrackRecaptcha}
                        disabled={
                            !isDevMode &&
                            stringToDigitsOnly(phone).length != PHONE_NUMBER_LENGTH
                        }
                        className={cn('w-full mt-10')}
                        size={'lg'}
                    >
                        {strings.submitButton}
                    </LoadingButton>
                </div>
            </div>
        </div>
    );
}

export default SignInSection;
