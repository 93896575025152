import { CandidateCareSetting, CandidateJobType, CandidateShift } from '@/lib/api/v1';

export enum OnboardingFormStep {
    LongTermInterest = 'longTermInterest',
    Profession = 'profession',
    JobTypes = 'jobTypes',
    Shifts = 'shifts',
    CareSettings = 'careSettings',
    AdditionalAttrs = 'addiationalAttributes',
    YearsExp = 'yearsOfExperience',
    ZipCode = 'zipcode',
    Name = 'fullname',
    Email = 'email',
    Register = 'register',
    Salary = 'salary',
    Resume = 'resume',
}

export enum OnboardingQuestion {
    LongTermInterest = 'longTermInterest',
    Profession = 'profession',
    JobTypes = 'jobTypes',
    Shifts = 'shifts',
    CareSettings = 'careSettings',
    HasCar = 'hasCar',
    HasDrivingLicense = 'hasDrivingLicense',
    YearsExp = 'yearsOfExperience',
    ZipCode = 'zipcode',
    Name = 'name',
    Email = 'email',
    Phone = 'phoneNumber',
    Salary = 'salary',
    RecentJobCompany = 'recentJobCompanyName',
    RecentJobTitle = 'recentJobPositionTitle',
    RecentJobStart = 'recentJobStartDate',
    RecentJobEnd = 'recentJobEndDate',
    RecentJobIsCurrent = 'recentJobIsCurrentJob',
}

export interface OnboardingAnswerData {
    [OnboardingQuestion.LongTermInterest]: boolean | null;
    [OnboardingQuestion.CareSettings]: CandidateCareSetting[];
    [OnboardingQuestion.Profession]: string;
    [OnboardingQuestion.JobTypes]: CandidateJobType[];
    [OnboardingQuestion.Shifts]: CandidateShift[];
    [OnboardingQuestion.HasCar]: boolean | null;
    [OnboardingQuestion.HasDrivingLicense]: boolean | null;
    [OnboardingQuestion.YearsExp]: number | null;
    [OnboardingQuestion.ZipCode]: string;
    [OnboardingQuestion.Name]: string;
    [OnboardingQuestion.Email]: string;
    [OnboardingQuestion.Phone]: string;
    [OnboardingQuestion.Salary]: number | null;
    [OnboardingQuestion.RecentJobCompany]: string;
    [OnboardingQuestion.RecentJobTitle]: string;
    [OnboardingQuestion.RecentJobStart]: string;
    [OnboardingQuestion.RecentJobEnd]: string;
    [OnboardingQuestion.RecentJobIsCurrent]: boolean;
}

export type OnboardingQuestionWithAnswer = {
    [T in OnboardingQuestion]: T extends keyof OnboardingAnswerData
        ? { question: T; answer: OnboardingAnswerData[T] }
        : { question: T };
}[OnboardingQuestion];
