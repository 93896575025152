import Logger from '@/_helpers/logger';
import { isEmpty } from 'lodash-es';

import { ZipcodeData, ZipcodeResponse } from './types';

const BASE_URL = 'https://api.zippopotam.us/us';

export async function validateZipcode(zipcode: string): Promise<string | null> {
    try {
        const response = await fetch(`${BASE_URL}/${zipcode}`);
        if (!response.ok) {
            return null;
        }
        const data = (await response.json()) as ZipcodeResponse;
        if (isEmpty(data)) {
            return null;
        }
        const place = (data as ZipcodeData).places[0];
        return `${place['place name']}, ${place['state abbreviation']}`;
    } catch (error) {
        Logger.error(error);
    }
    return null;
}
