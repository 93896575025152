import useExternalState from '@/hooks/useExternalState';
import { cn } from '@/lib/utils';
import L, { IconOptions as LeafletIconOptions } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, MapContainerProps, Marker, TileLayer } from 'react-leaflet';

import ReturnToCenterButton from './ReturnToCenterButton';
import MapZoomEndListener from './ZoomEndListener';
import './extraLeafletStyling.css';

L.Icon.Default.mergeOptions({
    iconRetinaUrl: '/images/marker-icon-2x.png',
    iconUrl: '/images/marker-icon.png',
    shadowUrl: '/images/marker-shadow.png',
});

const customIconOptions: LeafletIconOptions = {
    iconUrl: '/img/marker-icon.png',
    iconRetinaUrl: '/img/marker-icon-2x.png',
    shadowUrl: '/img/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
};

const customIcon = new L.Icon(customIconOptions);

function StaticMapComponent({
    lat,
    lng,
    zoom = 9,
    waitForLoad = true,
    locations = [],
    ready: externalReady,
    setReady: setExternalReady,
    ...mapProps
}: MapContainerProps & {
    lat: number;
    lng: number;
    zoom?: number;
    waitForLoad?: boolean;
    locations?: { lat: number; lng: number }[];
    ready?: boolean;
    setReady?: (ready: boolean) => void;
}) {
    const [ready, setReady] = useExternalState(externalReady, setExternalReady);

    const onLoad = () => {
        setReady(true);
    };

    return (
        <div
            className={cn('relative w-full h-full', {
                'opacity-0': !ready && waitForLoad,
            })}
        >
            <MapContainer
                center={[lat, lng]}
                zoom={zoom}
                className={cn('w-full h-full')}
                scrollWheelZoom={false}
                dragging={false}
                doubleClickZoom={false}
                zoomControl={false}
                touchZoom={false}
                keyboard={false}
                {...mapProps}
            >
                <MapZoomEndListener />
                <TileLayer
                    url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'
                    attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
                    eventHandlers={{
                        load: onLoad,
                    }}
                />
                {locations.map((l) => (
                    <Marker
                        key={`${l.lat}---${l.lng}`}
                        position={[l.lat, l.lng]}
                        icon={customIcon}
                    />
                ))}
                {mapProps?.dragging && <ReturnToCenterButton lat={lat} lng={lng} />}
            </MapContainer>
        </div>
    );
}

export default StaticMapComponent;
