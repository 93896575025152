import {
    EventType,
    OrgAdvantage,
    OrgBenefit,
    OrgPerkCategory,
    ProcessStatus,
    components,
} from '@/lib/api/v1';

type models = components['schemas'];

export enum ApiErrorType {
    INTERNAL_ERROR = 'INTERNAL_SERVER_ERROR',
    PHONE_NUMBER_TAKEN = 'PHONE_NUMBER_TAKEN',
    REQUEST_VALIDATION_ERROR = 'REQUEST_VALIDATION_ERROR',
}
export type ApiError = {
    detail: string;
    type?: ApiErrorType;
    traceId?: string;
    extra_data?: object;
};

export type EventableProcessStatus =
    | ProcessStatus.PhoneInterview
    | ProcessStatus.InPersonInterview
    | ProcessStatus.Orientation;

export type OrganizationBenefitModel = models['OrganizationPerkOut'] & {
    category: OrgPerkCategory.Benefit;
    name: OrgBenefit;
};
export type OrganizationAdvantageModel = models['OrganizationPerkOut'] & {
    category: OrgPerkCategory.Advantage;
    name: OrgAdvantage;
};
export type Organization = models['OrganizationOut'];
export type Candidate = models['CandidateOutSelf'];
export type ProcessEvent = models['FlatCandidateEventOut'];
export type ProcessApiModel = models['ProcessOut'];
export type Process = ProcessApiModel & {
    organization: Organization;
};

export type EventRelatedProcess = Process & {
    status: EventableProcessStatus;
};
export type InterviewRelatedProcess = Process & {
    status: ProcessStatus.PhoneInterview | ProcessStatus.InPersonInterview;
};
export type ProcessWithEvent = EventRelatedProcess & {
    relevantEvent: NonNullable<Process['relevantEvent']>;
};

export type JobMatch = models['JobMatchOut'];
export type AppStateModel = models['CandidateAppStateOut'];
export type ScreeningEvent = models['ScreeningOut'];
export type Facility = models['FacilityOut'];
export type FlatCandidateEvent = models['FlatCandidateEventOut'];

export type CandidateUpdatePayload = models['CandidateUpdateSelf'];
export type AppStateUpdatePayload = models['CandidateAppStateUpdate'];

export type AvailabilityWindow = models['TimeFrame'];
export type ProcessHistory = models['ProcessHistoryOut'];

export const processStatusToEventTypeMap: Partial<Record<ProcessStatus, EventType>> = {
    [ProcessStatus.PhoneInterview]: EventType.PhoneInterview,
    [ProcessStatus.InPersonInterview]: EventType.InPersonInterview,
    [ProcessStatus.Orientation]: EventType.Orientation,
};

export const eventableProcessStatuses = [
    ProcessStatus.PhoneInterview,
    ProcessStatus.InPersonInterview,
    ProcessStatus.Orientation,
];
