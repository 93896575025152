import { Button, PageLoader } from '@/components/ui';
import useProcesses from '@/fetchers/useProcesses';
import { EventStatus } from '@/lib/api/v1';
import { ProcessWithEvent, StatusPageTab } from '@/lib/types';
import { cn, isInProgress, valuesOf } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { statusPage as strings } from '@/strings';
import { useEffect } from 'react';

import AppPageLayout from '../AppPageLayout';
import ProcessesView from './ProcessesView';
import StatusPageHeader from './StatusPageHeader';
import UpcomingEventsView from './UpcomingEventsView';
import useTabFromHash from './useTabFromHash';

function StatusPage() {
    const [tab, setTab] = useTabFromHash();
    const { isReady, data: processesById, refresh } = useProcesses();
    const ongoingProcesses = valuesOf(processesById).filter(isInProgress);
    const processesWithUpcomingEvents = ongoingProcesses
        .filter(
            (p) => !!p.relevantEvent && p.relevantEvent.status === EventStatus.Scheduled,
        )
        .map((p) => p as ProcessWithEvent);

    useEffect(() => {
        if (tab === StatusPageTab.CurrentProcesses) {
            trackEvent(MixpanelEvent.ViewSectionCurrentProcesses);
        } else if (tab === StatusPageTab.UpcomingEvents) {
            trackEvent(MixpanelEvent.ViewSectionUpcomingEvents);
        }
    }, [tab]);

    useEffect(refresh, [refresh]);

    return (
        <AppPageLayout header={<StatusPageHeader />}>
            <div className={cn('relative h-full w-full  flex flex-col overflow-hidden')}>
                <div
                    className={cn(
                        'p-8 pb-4 flex items-center justify-center gap-4 w-full',
                    )}
                >
                    <Button
                        className={cn(
                            'text-13 px-4 transition-all duration-200 flex-grow max-w-[200px] rounded-full',
                        )}
                        variant={
                            tab === StatusPageTab.CurrentProcesses
                                ? 'default'
                                : 'outlinedAction'
                        }
                        onClick={() => setTab(StatusPageTab.CurrentProcesses)}
                    >
                        {strings.tabs.currentProcesses}
                    </Button>
                    <Button
                        className={cn(
                            'text-13 px-4 transition-all duration-200 flex-grow max-w-[200px] rounded-full',
                        )}
                        variant={
                            tab === StatusPageTab.UpcomingEvents
                                ? 'default'
                                : 'outlinedAction'
                        }
                        onClick={() => setTab(StatusPageTab.UpcomingEvents)}
                    >
                        {strings.tabs.upcomingEvents}
                    </Button>
                </div>
                <div className={cn('relative flex-grow w-full')}>
                    <div className={cn('absolute h-full w-full')}>
                        <div className={cn('relative h-full w-full')}>
                            {!isReady && <PageLoader />}
                            {isReady && (
                                <>
                                    {tab === StatusPageTab.CurrentProcesses && (
                                        <ProcessesView processes={ongoingProcesses} />
                                    )}
                                    {tab === StatusPageTab.UpcomingEvents && (
                                        <UpcomingEventsView
                                            processesWithUpcomingEvents={processesWithUpcomingEvents.filter(
                                                (p) =>
                                                    p.relevantEvent.status ===
                                                    EventStatus.Scheduled,
                                            )}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </AppPageLayout>
    );
}

export default StatusPage;
