import { TextInput, TextInputProps } from '@/components/ui';
import useExternalState from '@/hooks/useExternalState';
import { cn } from '@/lib/utils';
import strings from '@/strings/reusableComponents/phoneInput';

import { PHONE_NUMBER_LENGTH } from './consts';
import usePhoneInputVal from './usePhoneInputVal';

function PhonePrefix() {
    return (
        <div className={cn('whitespace-nowrap text-16 items-center mr-1')}>🇺🇸 +1</div>
    );
}

type PhoneNumberFormProps = Omit<TextInputProps, 'clearError'> & {
    setError?: (error: string) => void;
};

function PhoneNumberInput({
    value: phone,
    setValue: setPhone,
    error: externalError,
    setError: externalSetError,
    ...props
}: PhoneNumberFormProps) {
    const [error, setError] = useExternalState(externalError, externalSetError, {
        defaultVal: '',
    });
    const {
        phoneNumber,
        setPhone: updatePhone,
        phoneInputRef,
    } = usePhoneInputVal({ phone, setPhone });

    return (
        <TextInput
            ref={phoneInputRef}
            type='tel'
            prefixElement={<PhonePrefix />}
            maxLength={
                phoneNumber.length >= PHONE_NUMBER_LENGTH ? phone.length : undefined
            }
            placeholder={strings.placeholder}
            {...props}
            error={error}
            clearError={() => setError('')}
            value={phone}
            setValue={updatePhone}
        />
    );
}

export default PhoneNumberInput;
