import useExternalState from '@/hooks/useExternalState';
import { formatPhoneNumberToInputVal, stringToDigitsOnly } from '@/lib/utils';
import { useCallback, useEffect, useRef } from 'react';

import { PHONE_NUMBER_LENGTH } from './consts';

function getDigitsPosition(formattedPhoneNumber: string, cursorPosition: number) {
    let digitCount = 0;
    for (let i = 0; i < cursorPosition; i++) {
        if (/\d/.test(formattedPhoneNumber[i])) {
            digitCount++;
        }
    }
    return digitCount;
}

function getCursorPosition(formattedPhoneNumber: string, digitsPosition: number) {
    let digitsCount = 0;
    for (let i = 0; i < formattedPhoneNumber.length; i++) {
        if (/\d/.test(formattedPhoneNumber[i])) {
            digitsCount++;
            if (digitsCount > digitsPosition) {
                return i;
            }
        }
    }
    return formattedPhoneNumber.length;
}

function getNewCursorPosition({
    newValue,
    cursorPosition,
    formattedValue,
    hasChange,
}: {
    newValue: string;
    formattedValue: string;
    hasChange: boolean;
    cursorPosition: number;
}) {
    const digitsPos = getDigitsPosition(newValue, cursorPosition);
    const formattedPosition = getCursorPosition(formattedValue, digitsPos);
    if (!hasChange) return cursorPosition;
    return (
        cursorPosition +
        (formattedValue.slice(0, formattedPosition).length -
            newValue.slice(0, cursorPosition).length)
    );
}

function updateCursorPosition({
    inputElement,
    ...extraProps
}: {
    inputElement: HTMLInputElement | null;
    newValue: string;
    formattedValue: string;
    hasChange: boolean;
}) {
    if (!inputElement) return;
    const cursorPosition = inputElement.selectionStart;
    if (cursorPosition === null) return;
    const newCursorPosition = getNewCursorPosition({
        cursorPosition,
        ...extraProps,
    });
    requestAnimationFrame(() => {
        inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
    });
}

function usePhoneInputVal({
    phone: externalPhoneState,
    setPhone: externalSetPhoneState,
    phoneInputRef: externalInputRef,
}: {
    phone?: string;
    setPhone?: (phone: string) => void;
    phoneInputRef?: React.RefObject<HTMLInputElement>;
}) {
    const [phone, setPhone] = useExternalState(
        externalPhoneState,
        externalSetPhoneState,
    );
    const internalInputRef = useRef<HTMLInputElement>(null);
    const phoneInputRef = externalInputRef ?? internalInputRef;

    const phoneNumber = stringToDigitsOnly(phone);

    const updatePhone = useCallback(
        (value: string) => {
            const newPhoneNumber = stringToDigitsOnly(value);
            const formatted = formatPhoneNumberToInputVal(newPhoneNumber);
            setPhone(formatted);
            updateCursorPosition({
                inputElement: phoneInputRef.current,
                newValue: value,
                formattedValue: formatted,
                hasChange: phoneNumber !== newPhoneNumber,
            });
        },
        [phoneNumber, setPhone, phoneInputRef],
    );

    const additionalChars = phone.length - phoneNumber.length;

    useEffect(() => {
        if (phoneNumber.length > PHONE_NUMBER_LENGTH) {
            updatePhone(
                formatPhoneNumberToInputVal(phoneNumber.slice(0, PHONE_NUMBER_LENGTH)),
            );
        }
    }, [phoneNumber, updatePhone]);

    return {
        phone, // formatted phone number for text input
        setPhone: updatePhone, // handle new input value
        phoneNumber, // phone number without formatting
        additionalChars, // additional characters count in the formatted phone number
        phoneInputRef, // ref for the input element
    };
}

export default usePhoneInputVal;
