import { ProcessAction } from '@/lib/api/v1';
import { ComputedProcessStep, Process } from '@/lib/types';
import { getComputedProcessStep } from '@/lib/utils';
import { processes as strings } from '@/strings';

import ProcessStatusBadge from '../ProcessStatusBadge';
import InterviewRequestActions from './InterviewRequestActions';
import NewProcessActions from './NewProcessActions';
import PaperworkActions from './PaperworkActions';
import PostEventActions from './PostEventActions';
import ProcessStatusDisplay from './ProcessStatusDisplay';
import ScheduledEventActions from './ScheduledEventActions';

function ProcessActions({
    process,
    shouldShowEventActions,
    contextComponentName,
}: {
    process: Process;
    shouldShowEventActions: boolean;
    contextComponentName: string;
}) {
    const computedStep = getComputedProcessStep(process);

    const canTerminateProcess = process.actions.includes(ProcessAction.TerminateProcess);
    const canScheduleEvent = process.actions.includes(ProcessAction.CreateEvent);
    const showActions =
        shouldShowEventActions && (canTerminateProcess || canScheduleEvent);
    return (
        <>
            {computedStep === ComputedProcessStep.New && (
                <NewProcessActions
                    process={process}
                    contextComponentName={contextComponentName}
                />
            )}
            {computedStep === ComputedProcessStep.InterviewRequested && (
                <>
                    {showActions && (
                        <InterviewRequestActions
                            process={process}
                            contextComponentName={contextComponentName}
                            canSchedule={canScheduleEvent}
                            canTerminate={canTerminateProcess}
                        />
                    )}
                    {!showActions && (
                        <ProcessStatusDisplay
                            process={process}
                            substatusView={strings.subStatuses.interviewRequestPending}
                        />
                    )}
                </>
            )}
            {computedStep === ComputedProcessStep.PendingOrientationDate && (
                <ProcessStatusDisplay
                    process={process}
                    substatusView={strings.subStatuses.orientationRequestPending}
                />
            )}
            {computedStep === ComputedProcessStep.ScheduledEvent && (
                <ScheduledEventActions process={process} />
            )}
            {computedStep === ComputedProcessStep.PostEvent && (
                <PostEventActions process={process} />
            )}
            {computedStep === ComputedProcessStep.Paperwork && (
                <PaperworkActions process={process} />
            )}
            {computedStep === ComputedProcessStep.Terminated && (
                <ProcessStatusBadge process={process} />
            )}
            {computedStep === ComputedProcessStep.Hired && (
                <ProcessStatusBadge process={process} />
            )}
        </>
    );
}

export default ProcessActions;
