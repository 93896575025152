import Loader from '@/components/lotties/Loader';
import useCurrentScreeningEvent from '@/fetchers/useCurrentScreeningEvent';
import useProcessModels from '@/fetchers/useProcessesModels';
import { cn, isInProgress, valuesOf } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { useEffect } from 'react';

import { ScreeningFlowState } from '../types';
import useScreeningState from '../useScreeningState';
import PostScreeningMsg from './PostScreeningMsg';
import ScheduleIntroCallSection from './ScheduleIntroCallSection';
import ScreeningEventView from './ScreeningEventView';
import ScreeningSectionFooter from './ScreeningSectionFooter';

function ScreeningSection() {
    const screeningState = useScreeningState();
    const { refresh } = useCurrentScreeningEvent();
    const { data: processes } = useProcessModels();
    const hasOngoingProcesses = valuesOf(processes).some(isInProgress);

    useEffect(() => {
        if (screeningState === ScreeningFlowState.WaitingData) {
            return;
        }
        trackEvent(MixpanelEvent.VisitIntroCallSection, {
            intro_call_status:
                screeningState === ScreeningFlowState.Pending
                    ? 'should_schedule'
                    : screeningState,
        });
    }, [screeningState]);

    const isScreeningEventDataNeeded = [
        ScreeningFlowState.Pending,
        ScreeningFlowState.Scheduled,
    ].includes(screeningState);
    useEffect(() => {
        if (isScreeningEventDataNeeded) {
            refresh();
        }
    }, [refresh, isScreeningEventDataNeeded]);

    return (
        <>
            {screeningState === ScreeningFlowState.WaitingData && (
                <>
                    <div
                        className={cn(
                            'flex justify-center items-center py-12 h-64 w-full',
                        )}
                    >
                        <Loader variant='primary' sizing='lg' delay={700} />
                    </div>
                    <ScreeningSectionFooter
                        hasOngoingProcesses={hasOngoingProcesses}
                        withDelimiter
                    />
                </>
            )}
            {screeningState === ScreeningFlowState.Pending && (
                <ScheduleIntroCallSection />
            )}
            {screeningState === ScreeningFlowState.Scheduled && (
                <>
                    <ScreeningEventView />
                    <ScreeningSectionFooter
                        hasOngoingProcesses={hasOngoingProcesses}
                        withDelimiter={false}
                    />
                </>
            )}
            {screeningState === ScreeningFlowState.Done && (
                <>
                    <PostScreeningMsg />
                    <ScreeningSectionFooter
                        hasOngoingProcesses={hasOngoingProcesses}
                        withDelimiter
                    />
                </>
            )}
        </>
    );
}

export default ScreeningSection;
