import CodeInput from '@/components/ui/codeInput';
import { FIREBASE_INVALID_CODE_ERROR_CODE } from '@/firebase';
import { INTERNATIONAL_PREFIX, cn, formatInternationalPhoneNumber } from '@/lib/utils';
import { extractErrorType } from '@/lib/utils/errors';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import ResendCodeButton from '@/phoneSignIn/ResendCodeButton';
import { VERIFICATION_CODE_LENGTH } from '@/phoneSignIn/consts';
import { registration as strings } from '@/strings';
import { useCallback, useEffect, useState } from 'react';

import { useRegisterFormContext } from '../../RegisterContext';
import RegistrationStepLayout from '../../RegistrationStepLayout';
import StepTitle from '../../StepTitle';

function CodeVerificationForm({
    phone,
    submit,
    resendCode,
    goBack,
}: {
    phone: string;
    submit: (code: string) => Promise<void>;
    resendCode: () => Promise<void>;
    goBack: () => void;
}) {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');

    const { setCustomGoBackAction, setCustomGoNextAction, defaultGoNext } =
        useRegisterFormContext();

    const clearError = () => setError('');

    const submitCode = useCallback(async () => {
        trackEvent(MixpanelEvent.RegistrationSubmitVerificationCode);
        clearError();
        await submit(code)
            .then(defaultGoNext)
            .catch((error) => {
                let msg = strings.verificationCode.error;
                if (extractErrorType(error) === FIREBASE_INVALID_CODE_ERROR_CODE) {
                    msg = strings.verificationCode.invalidCodeError;
                }
                setError(msg);
            });
    }, [code, submit, defaultGoNext]);

    useEffect(() => {
        setCustomGoNextAction(submitCode);
        setCustomGoBackAction(goBack);
        return () => {
            setCustomGoNextAction(null);
            setCustomGoBackAction(null);
        };
    }, [submitCode, goBack, setCustomGoBackAction, setCustomGoNextAction]);

    const canGoNext = code.length === VERIFICATION_CODE_LENGTH;

    return (
        <RegistrationStepLayout
            canGoNext={canGoNext}
            additionalButtons={
                <ResendCodeButton
                    resendCode={async () => {
                        trackEvent(MixpanelEvent.RegistrationClickResendCode);
                        return await resendCode();
                    }}
                />
            }
        >
            <div className={cn('flex flex-col items-center gap-8')}>
                <div className={cn('max-w-full w-[350px]')}>
                    <StepTitle
                        title={strings.verificationCode.title}
                        subtitle={strings.verificationCode.subtitle.func(
                            formatInternationalPhoneNumber({
                                phone,
                                prefix: INTERNATIONAL_PREFIX,
                            }),
                        )}
                    />
                </div>
                <div className={cn('max-w-full w-[350px]')}>
                    <CodeInput
                        value={code}
                        setValue={setCode}
                        error={error}
                        clearError={clearError}
                        onSubmit={submitCode}
                    />
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default CodeVerificationForm;
