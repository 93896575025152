import { TerminateIcon } from '@/components/svgs';
import {
    CustomPopover,
    DialogHeaderAnchor,
    HorizontalFalafelButton,
    PopoverMenu,
    useDialogContext,
} from '@/components/ui';
import { ProcessAction } from '@/lib/api/v1';
import { DialogName, Process, TerminationDialogVariant } from '@/lib/types';
import { betweenLimits, cn } from '@/lib/utils';
import {
    MixpanelEvent,
    extractProcessDataForMixpanel,
    trackEvent,
} from '@/mixpanel/events';
import { processes as strings } from '@/strings';

import useOpenDialog from '../useOpenDialog';

const MAX_HEIGHT = 158;

export function ProcessHeader({ process }: { process: Process }) {
    const canTerminate = process.actions.includes(ProcessAction.TerminateProcess);
    const { openDialog: openTerminationDialog } = useOpenDialog(
        DialogName.TerminateProcess,
    );
    const { scrolledDownPosition } = useDialogContext();
    return (
        <div className={cn('cursor-grab')}>
            <div
                className={cn('relative pb-6 z-10')}
                style={{
                    backgroundColor: process.organization.brandColor ?? undefined,
                }}
            >
                <DialogHeaderAnchor anchorProps={{ className: cn('bg-white') }} />
            </div>
            <div
                className={cn('relative z-0')}
                style={{
                    backgroundColor: process.organization.brandColor ?? undefined,
                    height: betweenLimits(
                        MAX_HEIGHT - scrolledDownPosition,
                        0,
                        MAX_HEIGHT,
                    ),
                }}
            >
                <div
                    className={cn('absolute bottom-0 w-full pb-14 px-6')}
                    style={{
                        backgroundColor: process.organization.brandColor ?? undefined,
                    }}
                >
                    <div className={cn('relative flex justify-center')}>
                        <div
                            className={cn(
                                'h-[100px] w-[100px] rounded-full overflow-hidden flex items-center justify-center bg-white pointer-events-none select-none',
                            )}
                        >
                            <img
                                src={process.organization.logoUrl ?? undefined}
                                alt='icon'
                                className={cn('max-h-24 max-w-24 h-auto w-auto')}
                            />
                        </div>
                        {canTerminate && (
                            <CustomPopover
                                trigger={
                                    <HorizontalFalafelButton
                                        className={cn('absolute top-0 right-6')}
                                    />
                                }
                                onOpenCallback={() => {
                                    trackEvent(MixpanelEvent.ProcessCardOpenActionsMenu);
                                }}
                            >
                                <PopoverMenu
                                    items={[
                                        {
                                            id: 'terminate',
                                            content: {
                                                label: strings.actions.terminate,
                                                Icon: TerminateIcon,
                                            },
                                            onClick: () => {
                                                trackEvent(
                                                    MixpanelEvent.ProcessActionsMenuClickTermination,
                                                    {
                                                        ...extractProcessDataForMixpanel(
                                                            process,
                                                        ),
                                                    },
                                                );
                                                openTerminationDialog({
                                                    processId: process.id,
                                                    variant:
                                                        TerminationDialogVariant.Termination,
                                                });
                                            },
                                        },
                                    ]}
                                />
                            </CustomPopover>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProcessHeader;
