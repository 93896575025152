export type MonthAndYearInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    value: string;
    setValue: (val: string) => void;
    error: string;
};

export enum InnerDateType {
    Start = 'start',
    End = 'end',
}

export type InnerDateInputProps = {
    value: Date | null;
    setValue: (val: Date | null) => void;
    label?: string;
};

export type DateInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> &
    InnerDateInputProps & {
        endDate?: InnerDateInputProps;
        withEndDate?: boolean;
        disabled?: boolean;
        error?: string;
        setError?: (e: string) => void;
    };
