import Logger from '@/_helpers/logger';
import useCandidateId from '@/hooks/useCandidateId';
import apiClient from '@/lib/api';
import { getDateOrNull } from '@/lib/base';
import { cn, dateObjToDateString } from '@/lib/utils';
import { extractErrorMsg } from '@/lib/utils/errors';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { registration as strings } from '@/strings';
import { useState } from 'react';

import { useRegisterFormContext } from '../RegisterContext';
import RegistrationStepLayout from '../RegistrationStepLayout';
import StepTitle from '../StepTitle';
import LastWorkPlaceForm from './resume/LastWorkPlaceForm';
import OrDelimiter from './resume/OrDelimiter';
import SkipButton from './resume/SkipButton';
import UploadCVButton from './resume/UploadCVButton';
import useFinishRegistration from './useFinishRegistration';

function ResumeStep() {
    const {
        recentJobCompany: company,
        recentJobTitle: role,
        recentJobStart: startDateString,
        recentJobEnd: endDateString,
        recentJobIsCurrent: isCurrentJob,
        setRecentJobCompany: setCompany,
        setRecentJobTitle: setRole,
        setRecentJobStart: setStartDateString,
        setRecentJobEnd: setEndDateString,
        setRecentJobIsCurrent: setIsCurrentJob,
    } = useRegisterFormContext();
    const startDate = getDateOrNull(startDateString);
    const endDate = getDateOrNull(endDateString);
    const [dateError, setDateError] = useState('');
    const [cvFile, setCvFile] = useState<File | null>(null);
    const candidateId = useCandidateId();
    const finishRegistration = useFinishRegistration();

    const handleCvFileChange = (file: File | null) => {
        if (file) {
            trackEvent(MixpanelEvent.RegistrationSelectResumeFile, {
                filename: file.name,
            });
        } else {
            trackEvent(MixpanelEvent.RegistrationClearResumeFile);
        }
        setCvFile(file);
    };

    const uploadedFile = !!cvFile;
    const hasValidDateForLastWork =
        !!startDate && !dateError && (!!endDate || isCurrentJob);
    const hasValidLastWorkData = !!company && !!role && hasValidDateForLastWork;
    const canGoNext = uploadedFile || hasValidLastWorkData;

    const onSubmit = async () => {
        finishRegistration();
        if (uploadedFile) {
            return;
        }
        if (!hasValidLastWorkData) {
            return;
        }
        apiClient
            .POST('/api/v1/candidates/{candidate_id}/experiences', {
                params: { path: { candidate_id: candidateId } },
                body: {
                    companyName: company,
                    position: role,
                    startDate: dateObjToDateString(startDate!),
                    endDate: isCurrentJob ? null : dateObjToDateString(endDate!),
                    present: isCurrentJob,
                },
            })
            .then(() => {
                apiClient
                    .POST('/api/v1/candidates/{candidate_id}/resume/generate', {
                        params: { path: { candidate_id: candidateId } },
                    })
                    .catch((error) => {
                        Logger.error(extractErrorMsg(error));
                    });
            })
            .catch((error) => {
                Logger.error(extractErrorMsg(error));
            });
    };

    return (
        <RegistrationStepLayout
            canGoNext={canGoNext}
            sideEffefct={onSubmit}
            additionalButtons={<SkipButton />}
        >
            <div className={cn('w-full max-w-[400px] flex flex-col items-center')}>
                <div className={cn('w-full')}>
                    <StepTitle
                        title={strings.resume.title}
                        subtitle={strings.resume.subtitle}
                    />
                    <div className={cn('mt-10')}>
                        <UploadCVButton
                            candidateId={candidateId}
                            onFileChange={handleCvFileChange}
                            onOpenSelection={() => {
                                trackEvent(MixpanelEvent.RegistrationClickUploadResume);
                            }}
                        />
                    </div>
                    {!uploadedFile && (
                        <>
                            <div className={cn('py-14')}>
                                <OrDelimiter />
                            </div>
                            <LastWorkPlaceForm
                                company={company}
                                setCompany={setCompany}
                                role={role}
                                setRole={setRole}
                                startDate={startDate}
                                setStartDate={(date) =>
                                    setStartDateString(date?.toISOString() ?? '')
                                }
                                endDate={endDate}
                                setEndDate={(date) =>
                                    setEndDateString(date?.toISOString() ?? '')
                                }
                                dateError={dateError}
                                setDateError={setDateError}
                                isCurrentJob={isCurrentJob}
                                setIsCurrentJob={setIsCurrentJob}
                            />
                        </>
                    )}
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default ResumeStep;
