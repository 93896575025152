import { cn } from '@/lib/utils';

import FooterMenu from './appMenu';
import { useIsCurrentPageIdAnonymous } from './hooks/useCurrentPageId';
import ImpersonationController from './impersonation/ImpersonationController';
import MainRoutes from './routes/mainRoutes';

function AppLayout() {
    const isCurrentPageAnonymous = useIsCurrentPageIdAnonymous();
    return (
        <div className={cn('relative w-full h-full  bg-white')}>
            {isCurrentPageAnonymous && (
                <ImpersonationController>
                    <MainRoutes />
                </ImpersonationController>
            )}
            {!isCurrentPageAnonymous && (
                <>
                    <div
                        className={cn(
                            'relative w-full h-full pb-[66px] overflow-hidden',
                        )}
                    >
                        <div className={cn('relative w-full h-full  bg-white')}>
                            <ImpersonationController>
                                <MainRoutes />
                            </ImpersonationController>
                        </div>
                    </div>
                    <FooterMenu />
                </>
            )}
        </div>
    );
}
export default AppLayout;
