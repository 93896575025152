import { CurrentAuthUser } from '@/_selectors';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { MixpanelEvent, trackEvent } from './events';
import { isMixpanelOn } from './utils';

function useIdentifyMixpanelUser() {
    const user = useSelector(CurrentAuthUser);
    const { name: nameOfCurrentUser } = user ?? {};

    useEffect(() => {
        if (!isMixpanelOn() || !user) {
            return;
        }
        mixpanel.identify(user.phoneNumber);
        mixpanel.people.set_once({ $phone: user.phoneNumber });
        trackEvent(MixpanelEvent.Login);
    }, [user]);

    useEffect(() => {
        if (!isMixpanelOn() || !nameOfCurrentUser) {
            return;
        }
        mixpanel.people.set({ $name: nameOfCurrentUser });
    }, [nameOfCurrentUser]);
}

export default useIdentifyMixpanelUser;
