import { TextInput } from '@/components/ui';
import { cn } from '@/lib/utils';
import { registration as strings } from '@/strings';
import { validate as validateEmail } from 'email-validator';
import { useState } from 'react';

import { useRegisterFormContext } from '../RegisterContext';
import RegistrationStepLayout from '../RegistrationStepLayout';
import StepTitle from '../StepTitle';

function EmailStep() {
    const { email, setEmail } = useRegisterFormContext();
    const [error, setError] = useState('');

    const updateEmail = (val: string) => {
        setEmail(val);
        setError('');
    };

    const verifier = () => {
        if (!validateEmail(email)) {
            setError(strings.email.error);
            return false;
        }
        return true;
    };
    return (
        <RegistrationStepLayout canGoNext={!!email} verifier={verifier}>
            <div className={cn('w-full h-full flex flex-col items-center')}>
                <div className={cn('w-full max-w-[350px] flex flex-col gap-8')}>
                    <StepTitle
                        title={strings.email.title}
                        subtitle={strings.email.subtitle}
                    />
                    <TextInput
                        value={email}
                        setValue={updateEmail}
                        placeholder={strings.email.placeholder}
                        error={error}
                        clearError={() => setError('')}
                    />
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default EmailStep;
