import { Checkbox, TextInput } from '@/components/ui';
import DateInput from '@/components/ui/dateInput';
import { cn } from '@/lib/utils';
import { registration as registrationStrings } from '@/strings';

const strings = registrationStrings.resume.lastExperience;

function LastWorkPlaceForm({
    company,
    setCompany,
    role,
    setRole,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    dateError,
    setDateError,
    isCurrentJob,
    setIsCurrentJob,
}: {
    company: string;
    setCompany: (val: string) => void;
    role: string;
    setRole: (val: string) => void;
    startDate: Date | null;
    setStartDate: (val: Date | null) => void;
    endDate: Date | null;
    setEndDate: (val: Date | null) => void;
    dateError: string;
    setDateError: (val: string) => void;
    isCurrentJob: boolean;
    setIsCurrentJob: (val: boolean) => void;
}) {
    return (
        <div className={cn('w-full flex flex-col gap-10 pb-14')}>
            <div className={cn('text-16 font-bold')}>{strings.title}</div>
            <TextInput
                label={strings.companyLabel}
                placeholder={strings.companyPlaceholder}
                value={company}
                setValue={setCompany}
            />
            <TextInput
                label={strings.roleLabel}
                placeholder={strings.rolePlaceholder}
                value={role}
                setValue={setRole}
            />
            <DateInput
                value={startDate}
                setValue={setStartDate}
                label={strings.startDateLabel}
                endDate={{
                    value: endDate,
                    setValue: setEndDate,
                    label: strings.endDateLabel,
                }}
                error={dateError}
                setError={setDateError}
                withEndDate={!isCurrentJob}
            />
            <div className={cn('flex items-center gap-3')}>
                <Checkbox checked={isCurrentJob} onCheckedChange={setIsCurrentJob} />
                <div className={cn('text-15 leading-[1]')}>
                    {strings.isCurrentJobLabel}
                </div>
            </div>
        </div>
    );
}

export default LastWorkPlaceForm;
