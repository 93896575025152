import { DefaultDivProps } from '@/lib/base';
import { cn } from '@/lib/utils';

import { Checkbox, CheckboxProps } from './base';

function CheckboxWithLabel({
    label,
    labelProps,
    rootProps,
    hasError,
    checked,
    onCheckedChange,
    ...props
}: CheckboxProps & {
    label: string;
    labelProps?: DefaultDivProps;
    rootProps?: DefaultDivProps;
}) {
    return (
        <div
            tabIndex={-1}
            {...rootProps}
            className={cn(
                'inline-flex items-center gap-2 cursor-pointer',
                rootProps?.className,
            )}
            onClick={onCheckedChange ? () => onCheckedChange(!checked) : undefined}
        >
            <Checkbox
                hasError={hasError}
                checked={checked}
                onCheckedChange={onCheckedChange}
                {...props}
                onClick={(e) => {
                    props.onClick && props.onClick(e);
                    e.stopPropagation();
                }}
            />
            <div
                {...labelProps}
                className={cn(
                    'text-13 leading-[1] font-semibold',
                    'transition-colors ease-linear duration-150',
                    {
                        'text-red200': hasError,
                    },
                    labelProps?.className,
                )}
            >
                {label}
            </div>
        </div>
    );
}

export default CheckboxWithLabel;
