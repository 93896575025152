import useLocationHash from '@/hooks/useLocationHash';
import useSessionSetting from '@/hooks/useSessionSetting';
import useSetHash from '@/hooks/useSetHash';
import { getAllEnumValues } from '@/lib/base';
import { StatusPageTab } from '@/lib/types';
import { useEffect } from 'react';

const tabOptions = getAllEnumValues(StatusPageTab);

const useTabFromHash = () => {
    const setHash = useSetHash();
    const selectedTab = useLocationHash() as StatusPageTab;
    const { val: defaultTab, setVal: setDefaultTab } =
        useSessionSetting('statusPageTab');

    const setSelectedTab = (tab: StatusPageTab) => {
        setHash(tab);
    };

    useEffect(() => {
        if (!tabOptions.includes(selectedTab)) {
            setSelectedTab(defaultTab ?? StatusPageTab.CurrentProcesses);
        } else {
            setDefaultTab(selectedTab);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab]);

    return [selectedTab, setSelectedTab] as [
        StatusPageTab,
        (tab: StatusPageTab) => void,
    ];
};

export default useTabFromHash;
