import { cn } from '@/lib/utils';
import { ChangeEvent } from 'react';
import InputMask from 'react-input-mask';

import { MonthAndYearInputProps } from './types';

const DELIMITER = ' / ';

function MonthAndYearInput({
    value,
    setValue,
    error,
    ...props
}: MonthAndYearInputProps) {
    const indicateError = !!error;
    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        const newVal = ev.target.value;
        setValue(newVal);
    };

    return (
        <div className={cn('relative px-6')}>
            <InputMask
                mask={`99${DELIMITER}9999`}
                placeholder={`MM${DELIMITER}YYYY`}
                maskChar={'_'}
                value={value}
                onChange={handleChange}
            >
                {
                    (({ ...maskProps }) => (
                        <input
                            {...maskProps}
                            {...props}
                            className={cn(
                                'p-0 w-full',
                                'placeholder:font-medium placeholder:text-neutral300',
                                'focus-visible:outline-none',
                                'disabled:cursor-not-allowed',
                                { 'text-red200': indicateError },
                                props?.className,
                            )}
                        />
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    )) as any
                }
            </InputMask>
        </div>
    );
}

export default MonthAndYearInput;
