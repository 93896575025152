export enum AppPageId {
    Home = 'Home',
    Status = 'Status',
    Profile = 'Profile',
    Register = 'Register',
}

export const anonymousPageIds = [AppPageId.Register] as const;

export type AnonumousPageId = (typeof anonymousPageIds)[number];
