import useSetOpen from '@/hooks/useSetOpen';
import { useRef, useState } from 'react';

import { DialogBase, DialogTrigger } from './base';
import DialogContext from './context';
import DialogView from './dialogView';
import { CustomDialogProps, DialogAnimationType } from './types';

function Dialog({
    open: externalOpen,
    setOpen: setExternalOpen = () => {},
    trigger,
    animation = DialogAnimationType.SLIDE,
    triggerProps,
    overlayProps,
    portalProps,
    contentProps,
    dialogProps,
    contentWrapperProps,
    CustomWrapper,
    alwaysExpanded = false,
    ...contentViewProps
}: CustomDialogProps) {
    const { handleClose, handleOpen, setOpen, open } = useSetOpen(
        externalOpen,
        setExternalOpen,
    );
    const dialogContentRef = useRef<HTMLDivElement>(null);
    const scrollRef = useRef<HTMLDivElement>(null);

    const [draggedDownPosition, setDraggedDownPosition] = useState(0);
    const [scrolledDownPosition, setScrolledDownPosition] = useState(0);
    const [isExpandedToTop, setIsExpandedToTop] = useState(false);

    return (
        <DialogContext.Provider
            value={{
                handleClose,
                handleOpen,
                isExpandedToTop: alwaysExpanded || isExpandedToTop,
                setIsExpandedToTop,
                draggedDownPosition,
                setDraggedDownPosition,
                scrolledDownPosition,
                setScrolledDownPosition,
                open,
                dialogContentRef,
                scrollRef,
            }}
        >
            <DialogBase open={open} onOpenChange={setOpen} {...dialogProps}>
                {trigger && (
                    <DialogTrigger tabIndex={-1} {...triggerProps}>
                        {trigger}
                    </DialogTrigger>
                )}
                <DialogView
                    {...{
                        portalProps,
                        overlayProps,
                        contentProps,
                        animation,
                        contentWrapperProps,
                        CustomWrapper,
                        ...contentViewProps,
                        handleClose,
                        open,
                    }}
                />
            </DialogBase>
        </DialogContext.Provider>
    );
}

function DialogFadeAnimation({ ...props }: Omit<CustomDialogProps, 'animate'>) {
    return (
        <Dialog
            animation={DialogAnimationType.FADE}
            {...{
                ...props,
            }}
        />
    );
}

export { DialogFadeAnimation };
export default Dialog;
