import { AppPageId } from '@/lib/types';
import { matchPath } from 'react-router-dom';

import { keysOf } from './typescript';

export const routesMap: Record<AppPageId, string> = {
    [AppPageId.Status]: '/status',
    [AppPageId.Profile]: '/profile',
    [AppPageId.Register]: '/register',
    [AppPageId.Home]: '/',
};

export const appPageIdsSorted = keysOf(routesMap);

export function getCurrentPageId(pathname: string): AppPageId | undefined {
    for (const pageId of keysOf(routesMap)) {
        if (matchPath(pathname, routesMap[pageId])) {
            return pageId;
        }
    }
}

export function getRouteForPageId(pageId: AppPageId) {
    for (const [page, path] of Object.entries(routesMap)) {
        if (page === pageId) {
            return path;
        }
    }
    return '';
}
