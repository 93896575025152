import useCurrentCandidate from '@/fetchers/useCurrentCandidate';
import useCurrentScreeningEvent from '@/fetchers/useCurrentScreeningEvent';
import useProcessModels from '@/fetchers/useProcessesModels';
import { ScreeningStatus } from '@/lib/api/v1';
import { isInProgress, valuesOf } from '@/lib/utils';

import { ScreeningFlowState } from './types';

function useScreeningState(): ScreeningFlowState {
    const { isReady, data: screeningEvent } = useCurrentScreeningEvent();
    const { isReady: isCandidateDataReady, data: currentCandidate } =
        useCurrentCandidate();
    const { isReady: isProcessesReady, data: processes } = useProcessModels();

    if (!isCandidateDataReady) {
        return ScreeningFlowState.WaitingData;
    }
    if (currentCandidate.hadIntroConversation) {
        if (!isProcessesReady) {
            return ScreeningFlowState.WaitingData;
        }
        if (valuesOf(processes).some(isInProgress)) {
            return ScreeningFlowState.hasActiveProcesses;
        }
        return ScreeningFlowState.Done;
    }
    if (!isReady) {
        return ScreeningFlowState.WaitingData;
    }
    if (!screeningEvent) {
        return ScreeningFlowState.Pending;
    }
    if (screeningEvent.status === ScreeningStatus.Canceled) {
        return ScreeningFlowState.Pending;
    }
    return ScreeningFlowState.Scheduled;
}

export default useScreeningState;
