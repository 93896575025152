import { AnonumousPageId, anonymousPageIds } from '@/lib/types';
import { getCurrentPageId } from '@/lib/utils';
import { useLocation } from 'react-router-dom';

function useCurrentPageId() {
    const location = useLocation();
    return getCurrentPageId(location.pathname);
}

export function useIsCurrentPageIdAnonymous() {
    const currentPageId = useCurrentPageId();
    return anonymousPageIds.includes(currentPageId as AnonumousPageId);
}

export default useCurrentPageId;
