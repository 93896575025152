import useOpenDialog from '@/dialogs/useOpenDialog';
import { DivAttributes } from '@/lib/base';
import { ComputedProcessStep, DialogName, Process } from '@/lib/types';
import { cn, getComputedProcessStep, getSelectedJobMatch } from '@/lib/utils';
import {
    MixpanelEvent,
    extractProcessDataForMixpanel,
    trackEvent,
} from '@/mixpanel/events';
import { processes as processesStrings } from '@/strings';

import ProcessActions from '../ProcessActions/ProcessActions';

const strings = processesStrings.processCard;

interface CardProps {
    process: Process;
    isVertical: boolean;
    rootProps?: DivAttributes;
}

function ProcessCard({ process, rootProps, isVertical }: CardProps) {
    const { openDialog } = useOpenDialog(DialogName.ProcessDetails);
    const jm = getSelectedJobMatch(process.jobMatches);
    const facility = jm?.facility;
    const shouldShowEventActions = isVertical;
    const hasEventActions =
        getComputedProcessStep(process) === ComputedProcessStep.InterviewRequested;
    return (
        <div
            {...rootProps}
            className={cn(
                'relative min-w-[300px] w-[300px] rounded-2xl shadow-elevation-300 bg-white overflow-hidden cursor-pointer',
                'flex flex-col items-stretch',
                { 'max-w-full w-[362px]': isVertical },
                rootProps?.className,
            )}
            onClick={() => {
                trackEvent(MixpanelEvent.ExpandProcessCard, {
                    ...extractProcessDataForMixpanel(process),
                });
                openDialog({ processId: process.id });
            }}
        >
            <div
                className={cn('h-28 w-full flex items-center p-7')}
                style={{ background: process.organization.brandColor ?? undefined }}
            >
                <div
                    className={cn(
                        'w-14 h-14 rounded-full bg-white overflow-hidden pointer-events-none select-none',
                        'flex items-center justify-center',
                    )}
                >
                    <img
                        src={process.organization.logoUrl ?? undefined}
                        alt='icon'
                        className={cn('max-h-12 w-auto')}
                    />
                </div>
            </div>
            <div className={cn('p-7 flex-grow')}>
                <div className={cn('text-16 leading-[26px] font-bold')}>
                    {facility?.name ?? ''}
                </div>
                <div className={cn('text-13-23 font-medium whitespace-pre-line')}>
                    {facility?.type ? strings.facilityTypeToString[facility.type] : ''}
                </div>
            </div>
            <div
                className={cn('flex items-center p-7 border-t border-neutral100', {
                    'border-none pt-3': shouldShowEventActions && hasEventActions,
                    'h-24': !isVertical,
                })}
            >
                <ProcessActions
                    process={process}
                    shouldShowEventActions={shouldShowEventActions}
                    contextComponentName={'ProcessCardThumbnail'}
                />
            </div>
        </div>
    );
}

export default ProcessCard;
