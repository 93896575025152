import { LocationIcon } from '@/components/svgs';
import { TextInput } from '@/components/ui';
import { cn, stringToDigitsOnly } from '@/lib/utils';
import { validateZipcode } from '@/lib/zippopotam';
import { registration as strings } from '@/strings';
import { useEffect, useRef, useState } from 'react';

import { useRegisterFormContext } from '../RegisterContext';
import RegistrationStepLayout from '../RegistrationStepLayout';
import StepTitle from '../StepTitle';

function ZipCodeStep() {
    const { zipcode, setZipcode, zipcodeLocation, setZipcodeLocation } =
        useRegisterFormContext();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const loadingZipRef = useRef<string>();
    const isZipcodeReady = zipcode.length >= 5;

    const updateVal = (val: string) => {
        setZipcode(stringToDigitsOnly(val));
    };

    useEffect(() => {
        setError('');
        if (!isZipcodeReady) {
            setLoading(false);
            setZipcodeLocation('');
            return;
        }
        setLoading(true);
        loadingZipRef.current = zipcode;
        validateZipcode(zipcode)
            .then((location) => {
                if (loadingZipRef.current !== zipcode) {
                    return;
                }
                if (location === null) {
                    setError(strings.zipcode.error);
                }
                setZipcodeLocation(location ?? '');
            })
            .finally(() => {
                if (loadingZipRef.current !== zipcode) {
                    return;
                }
                setLoading(false);
            });
    }, [zipcode, isZipcodeReady, setZipcodeLocation, setLoading]);
    const shouldShowLocation = !!zipcodeLocation && !loading && isZipcodeReady;
    return (
        <RegistrationStepLayout canGoNext={shouldShowLocation}>
            <div className={cn('w-full h-full flex flex-col items-center')}>
                <div className={cn('flex flex-col gap-8')}>
                    <StepTitle
                        title={strings.zipcode.title}
                        subtitle={strings.zipcode.subtitle}
                    />
                    <div
                        className={cn(
                            'w-full max-w-[350px] flex flex-col items-center gap-8',
                        )}
                    >
                        <TextInput
                            type='tel'
                            maxLength={5}
                            value={zipcode}
                            error={error}
                            clearError={() => setError('')}
                            placeholder={strings.zipcode.placeholder}
                            setValue={updateVal}
                            loading={loading}
                        />
                        {shouldShowLocation && (
                            <div
                                className={cn(
                                    'inline-flex items-center justify-center gap-2 h-16 px-6 rounded-full bg-accent300',
                                )}
                            >
                                <LocationIcon />
                                <div className={cn('text-14 font-semibold')}>
                                    {zipcodeLocation}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </RegistrationStepLayout>
    );
}

export default ZipCodeStep;
