import { AppPageId, QueryParam } from '@/lib/types';
import { getRouteForPageId } from '@/lib/utils';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function useGoTo() {
    const navigate = useNavigate();
    const location = useLocation();
    const getSearchWithParams = (params: Partial<Record<QueryParam, string>>) => {
        const searchParams = new URLSearchParams(location.search);
        Object.entries(params).forEach(([key, value]) => {
            searchParams.set(key, value);
        });
        return searchParams.toString();
    };

    const goToHome = ({ phoneNumber }: { phoneNumber?: string } = {}) =>
        navigate({
            pathname: getRouteForPageId(AppPageId.Home),
            search: getSearchWithParams({
                ...(phoneNumber ? { phonenumber: phoneNumber } : {}),
            }),
        });
    const goToProfilePage = () =>
        navigate({
            pathname: getRouteForPageId(AppPageId.Profile),
            search: getSearchWithParams({}),
        });
    const goToStatusPage = () =>
        navigate({
            pathname: getRouteForPageId(AppPageId.Status),
            search: getSearchWithParams({}),
        });
    const goToRegister = () =>
        navigate({
            pathname: getRouteForPageId(AppPageId.Register),
            search: getSearchWithParams({}),
        });

    return useMemo(
        () => ({
            goToHome,
            goToRegister,
            goToProfilePage,
            goToStatusPage,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [navigate],
    );
}

export default useGoTo;
