import { useAppDispatch } from '@/_helpers/store';
import { exitDevMode } from '@/_reducers/devMode';
import { IsDevModeOn } from '@/_selectors';
import { ArrowLeft } from '@/components/svgs';
import useEnterDevMode from '@/hooks/useEnterDevMode';
import { cn } from '@/lib/utils';
import { XIcon } from 'lucide-react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

function DevModeIndication() {
    const dispatch = useAppDispatch();
    const exit = () => {
        console.log('Developer mode deactivated!');
        dispatch(exitDevMode());
    };
    const [isTop, setIsTop] = useState(false);
    const [isLeft, setIsLeft] = useState(true);

    return (
        <div
            className={cn(
                'fixed p-2 bg-sky-300/80 z-[1000]',
                'flex items-center gap-4',
                {
                    'rounded-t-2xl bottom-0': !isTop,
                    'rounded-b-2xl top-0': isTop,
                    'flex-row-reverse left-0': isLeft,
                    'right-0': !isLeft,
                },
            )}
        >
            <ArrowLeft
                className={cn('h-6 w-6 cursor-pointer', { 'rotate-180': isLeft })}
                onClick={() => setIsLeft((current) => !current)}
            />
            <ArrowLeft
                className={cn('h-6 w-6 cursor-pointer', {
                    'rotate-90': !isTop,
                    '-rotate-90': isTop,
                })}
                onClick={() => setIsTop((current) => !current)}
            />
            <div className={cn('text-18 font-semibold mx-6')}>Dev Mode</div>
            <XIcon className={cn('h-7 w-7 cursor-pointer stroke-[3]')} onClick={exit} />
        </div>
    );
}

function DevModeProvider() {
    useEnterDevMode();
    const isOn = useSelector(IsDevModeOn);

    return isOn ? <DevModeIndication /> : null;
}

export default DevModeProvider;
