import { SearchingLottie } from '@/components/lotties';
import { GeoLocation } from '@/lib/types';
import { cn } from '@/lib/utils';
import StaticMapComponent from '@/staticMap/StaticMapComponent';

function SearchRadar({ lat, lng }: GeoLocation) {
    return (
        <div className='relative flex w-[250px] h-[250px] items-center justify-center rounded-full overflow-hidden'>
            <div className='relative flex w-full h-full items-center justify-center'>
                <div
                    className={cn(
                        'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[200%] h-[200%] z-0',
                    )}
                >
                    <StaticMapComponent {...{ lat, lng }} />
                </div>
                <SearchingLottie className='w-5/6 h-5/6 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10' />
                <div
                    className={cn(
                        'absolute h-full w-full origin-center animate-spin-2s z-10',
                    )}
                >
                    <div className={cn('relative h-full w-full')}>
                        <div
                            className={cn(
                                'rounded-full w-full h-full bg-radar-gradient',
                            )}
                        />
                        <svg
                            width='250'
                            height='250'
                            viewBox='0 0 200 200'
                            className={cn('absolute top-0 left-0')}
                        >
                            <line
                                x1='100'
                                y1='100'
                                x2='100'
                                y2='0'
                                stroke='black'
                                strokeWidth='2'
                            />
                            <circle cx='100' cy='100' r='4' fill='black' />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchRadar;
